import React from 'react';
import NavBar from './navBar';
import '../css/pageNotFound.css';

function PageNotFound(props) {
    return(
        <div>
        <div className="mic-background-image-pageNotFound">
        <NavBar/>
        <div className="format-div-pageNotFound"></div>

        <div className="h1-div-grey">
            <h1 className="catalog-title">404: Page not found.</h1>
            </div>
        </div>
    </div>
    );
}

export default PageNotFound;
