import React, {useState, useEffect, } from 'react';
import CustomerAccountContent from './CustomerAccountContent';
import PageForbidden from './PageForBidden';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {setToken, setAuthentication} from './cartSlice';
import '../css/customerAccount.css';



const CustomerAccount = (props)=> {
    //const url = process.env.REACT_APP_API_PATH+"JwtController.php";
    
   // const [isLoggedIn, setIsLoggedIn] = useState(false);

 //const setAuth = useDispatch();
   //const setTokenA = useDispatch();
   //const token = useSelector((state)=>state.cart.token);
   const isAuth = useSelector((state)=>state.cart.isAuth);
  



   /* useEffect(()=>{

        const url2 = "api/JwtController.php";
        axios.get(url2).then((response)=>{
            

            setAuth(setAuthentication(response.data.result));

        }).catch((error)=>{
            //console.log(error);
        });*
        
     },[]); */  
    return(
        <div>
        {isAuth ? <CustomerAccountContent/> : <PageForbidden/>}
        </div>
    );
}

export default CustomerAccount;