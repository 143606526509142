import React, {useEffect, useState} from 'react';
import '../css/product.css';



function Products(props) {
const [audioObject, setAudioObject] = useState(null);
const [isSelected, setIsSelected] = useState(false);
const [time, setTime] = useState(0.0);

const play = () =>{
    audioObject.play();
    setIsSelected(true);
}

const stop = () =>{
    audioObject.pause();
    setIsSelected(false);
}

const changeVolume = (number1, direction) =>{
    
    var number = 0;
    if(audioObject.volume < 1 && direction == "up"){
       number = audioObject.volume + (number1/100);
       if(number >= 1){
           audioObject.volume = 1;
       }
       else{
           audioObject.volume = number;
       }
    }

    if(audioObject.volume > 0 && direction == "down"){
       number = audioObject.volume - (number1/100);
        if(number <= 0){
            audioObject.volume = 0;
        }    
        else{
            audioObject.volume = number;
        }
   }
}


const checkUpdate = (event) =>{
   //console.log(event.srcElement.currentTime);
   
   var progression = (audioObject.currentTime/audioObject.duration) * 100;
   
   
   setTime(progression);
   if(audioObject.currentTime == audioObject.duration){
       audioObject.currentTime = 0;
       audioObject.pause();
       setIsSelected(false);
   }
}

if(audioObject != null){
audioObject.addEventListener('timeupdate',checkUpdate);
}

var progress = {
    width:Math.ceil(time).toString()+"%",
    backgroundColor:"white",
    height:"2px;"
}


useEffect(()=>{
    const demoUrl = "Demo/"+props.demoName;
    const audio = new Audio(demoUrl);
    setAudioObject(audio);
},[]
);
    return (
                <div className="product-div-catalog">
                    
                    <div className="product-info-div">
                    <div className="product-image-div">
                        <img className="product-image" src = {"ProductImage/"+ props.productImage} alt={props.productName}/>
                    </div>
                            <div className="product-price height-1">Price: ${props.itemPrice}</div>
                            <h2 className="height-1 product-name" style={{color:"white", size:"7px"}}>{props.itemName}</h2>
                            <div className="title-info-div">
                                 <div className="genre-title title-margin fontSize border backgroundColor" style={{width:"fit-content",display:"inline",marginRight:"10px"}}><b>Genre:</b></div><div className="genre-info fontSize border backgroundColor" style={{width:"fit-content",display:"inline"}}>{props.genre}</div>
                                 </div>
                                 <div className="title-info-div">
                                 <div className="product-title title-margin fontSize border backgroundColor" style={{width:"fit-content",display:"inline",marginRight:"10px"}}><b>Product Type:</b></div><div className="product-info fontSize border backgroundColor" style={{width:"fit-content",display:"inline"}}>{props.productType}</div>
                                 </div>
                    </div>   
                    
                    <div className="button-container">
                    {isSelected ? <div  className="button display" onClick={()=>stop()}><i className="fas fa-stop size-catalog"></i></div> : <div  className="button display" onClick={()=>play()}><i className="fas fa-play size-catalog"></i></div>}
                        <div className="button display marginLeft" onClick={()=>props.shoppingCart(props.id,props.itemName,props.itemPrice,props.productImage,props.fileName,props.zipFile)}>Add To Cart</div>
                    </div>  
                    {/****************************************************************************************/} 
                    <div className="info-grouping-format-div">
                    <div className="product-image-div2">
                        <img className="product-image" src = {"ProductImage/"+ props.productImage} alt={props.productName}/>
                    </div>
                    </div>

                    
                    <div className="info-grouping">
                    <div className="title-info-div2">
                                 <div className="genre-title title-margin fontSize border backgroundColor" style={{width:"fit-content",display:"inline",marginRight:"10px"}}><b>Genre:</b></div><div className="genre-info fontSize border backgroundColor" style={{width:"fit-content",display:"inline"}}>{props.genre}</div>
                                 </div>
                                 <div className="title-info-div2">
                                 <div className="product-title title-margin fontSize border backgroundColor" style={{width:"fit-content",display:"inline",marginRight:"10px"}}><b>Product Type:</b></div><div className="product-info fontSize border backgroundColor" style={{width:"fit-content",display:"inline"}}>{props.productType}</div>
                                 </div>
                    </div>  
                    <br className="break-catalog-small"/> 
                    
                    <div className="button-container2">
                         
                    {isSelected ? <div  className="button display" onClick={()=>stop()}><i className="fas fa-stop size-catalog"></i></div> : <div  className="button display" onClick={()=>play(props.demoName)}><i className="fas fa-play size-catalog"></i></div>}
                        <div className="button display marginLeft" style={{fontSize:"15px", padding:"8px"}} onClick={()=>props.shoppingCart(props.id,props.itemName,props.itemPrice,props.productImage,props.fileName,props.zipFile)}>Add To Cart</div>
                    </div>
                
                    <div className="chevron-div-catalog">
                        <p className="volume-control-catalog">VOLUME CONTROL</p>
                        <i class="fas fa-chevron-circle-up chevron-catalog" onClick={()=>changeVolume(10,"up")}></i>
                        <i class="fas fa-chevron-circle-down chevron-catalog" onClick={()=>changeVolume(10,"down")} style={{marginLeft:"50px"}}></i>
                    </div>
                </div>    
    );
}

export default Products;