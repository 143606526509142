import React, {useState,useEffect} from 'react';
import axios from 'axios';
import { useSelector, useDispatch} from 'react-redux';
import { setAuthentication, setToken,setAccountNo} from "./cartSlice";
import NavBar from './navBar';
import PageForBidden from './PageForBidden';
import '../css/personalInfo.css';
//import { event } from 'jquery';
//import { useLoaderData } from 'react-router-dom';



const PersonalInfo = (props)=>{

    //const [isAuth, setIsAuth] = useState(false);
    //const {result} = useLoaderData();
   

const [firstName, setFirstName] = useState("");
const [lastName, setLastName] = useState("");
const [userName, setUserName] = useState("");
const [companyName, setCompanyName] = useState("");
const [genre, setGenre,] = useState("");



var messageSwitch1 = false;
var messageSwitch2 = false;

const setAuth = useDispatch();
const isAuth = useSelector((state) => state.cart.isAuth);


const urlEdit = "api/EditInfoController.php";
const urlUpdate = "api/UpdateCustomerController.php";
const message = [
    {alertType:"alert alert-success alert-format", message:"Your personal information has been updated."},
    {alertType:"alert alert-danger alert-format", message:"There was a problem updating you personal information. Please contact the system administrator."}
];



const handleOnChange = (event)=>{

   
    if(event.target.name == 'firstName'){
        setFirstName(event.target.value);
    }
    if(event.target.name == 'lastName'){
        setLastName(event.target.value);
    }
    if(event.target.name == 'userName'){
        setUserName(event.target.value);
    }
    if(event.target.name == 'companyName'){
        setCompanyName(event.target.value);
    }
    if(event.target.name == 'genre'){
        setGenre(event.target.value);
    }

}


const handleOnSubmit = (event)=>{
    event.preventDefault();
   let info = {};
    
        info = {
        firstName,
        lastName,
        userName,
        companyName,
        genre
    }

  



    axios.post(urlUpdate,info).then((response)=>{
       
            if(response.data.result){
                setAuth(setAuthentication(response.data.result));
                messageSwitch1 = true;
                messageSwitch2 = false;
}
            else{
                messageSwitch2 = true;
                messageSwitch1 = false;
            }
    }).catch((error)=>{
        //console.log(error);
    });
}


    return (
        <div className="mic-background-image-personalInfo">
        <NavBar/>
        
    {!isAuth ? <PageForBidden/>: 
    <div>
        <div className="format-div-personalInfo"></div>
        <h1 className="catalog-title-personalInfo">Edit Personal Information</h1>
        <div className="product-container-personalInfo">
           
            <div className="web-text">
            <div>
           
             <form onSubmit={handleOnSubmit}>
             <div className="formDiv-personalInfo message-format">
                 {messageSwitch1 ? <div className={message[0].alertType} role="alert">{message[0].message}</div> : ""}
                 {messageSwitch2 ? <div className={message[1].alertType} role="alert">{message[1].message}</div> : ""}
            <label>First Name:</label> 
            <input type="text" name="firstName" onChange={handleOnChange} className="form-control" pattern="[a-zA-Z]+" placeholder="Enter First Name" title="First Name must not contain any numbers or special characters."/>
            <label>Last Name:</label>
            <input type="text" name="lastName"  onChange={handleOnChange} className="form-control" pattern="[a-zA-Z]+" placeholder="Enter Last Name" title="Last Name must not contain any numbers or special characters." />
            <label>User Name:</label>
            <input type="text" name="userName"  onChange={handleOnChange} className="form-control "  pattern="[a-zA-Z0-9]+" placeholder="Enter User Name" title="You must use only letters or numbers for the user name." />
            <label>Company Name:</label>
            <input type="text" name="companyName"  onChange={handleOnChange}  className="form-control"  placeholder="Enter Company Name" />
            <label>Genre Produced:</label>
            <input type="text" name="genre"  onChange={handleOnChange}  className="form-control " placeholder="Enter Genre" />
            <br/>
        </div>
        <br/>
        <div style={{width:"150px",margin:"auto"}}><button type="submit" name="submit" className="submit-button" style={{width:"100%"}}>Submit Changes</button></div>
        </form>
        </div>
     </div>
     </div>
     </div>}
     </div>

       
    );
}



export default PersonalInfo;