import React, {useState} from 'react';
import MenuItems from './MenuItems';


const SearchBar = (props) =>{

    const [showMenu, setShowMenu] = useState(false);
    const [displayText, setDisplayText] = useState(false);
    const [orderedBy, setOrderedBy ] = useState("");

    const showDropDown = ()=>{
        setShowMenu(true);
    }

    const handleDropDown = (number)=>{
        if (number == 0){
            setOrderedBy("Display All");
            setShowMenu(false);
            setDisplayText(false);
        }
        else if (number == 1){
            setOrderedBy("Hip Hop");
            setShowMenu(false);
            setDisplayText(true);
        }
        else if (number == 2){
            setOrderedBy("RnB");
            setShowMenu(false);
            setDisplayText(true);
        }
        else if (number == 3){
            setOrderedBy("Pop");
            setShowMenu(false);
            setDisplayText(true);
        }
        else if (number == 4){
            setOrderedBy("Dancehall");
            setShowMenu(false);
            setDisplayText(true);
        }
        else{
            setOrderedBy("Display All");
            setShowMenu(false);
            setDisplayText(false);
        }
        props.getOrderBy(number);
    }
    return(
       <div className="searchBar-button-container">
           <div className="sort-text-format" onClick={showDropDown}>Sort Items</div>
            {displayText ? <div>{"Ordered by: "+orderedBy}</div> : <div>Ordered by: Display All</div>}
            {showMenu ? <MenuItems handleDropDown={handleDropDown}/> : ""}
        </div>
    );
}

export default SearchBar;