import React,{useState, useEffect} from 'react';
import NavBar from './navBar';
import Captcha from './CaptchaImage2';
import axios from 'axios';
import {Link} from 'react-router-dom';
import "../css/product.css";
import "../css/newCustomerForm.css";
import TermsModal from "./TermsModal";
import PrivacyModal from './PrivacyModal';
import "../css/Modal.css";

const initialState = {
  messageTextBox: "",
  messageNoNumbers: "",
  messagePassConf: "",
  textBoxValidation: false,
  noNumbersValidation: false,
  passConfValidation: false,
  highLightFName: false,
  highLightLName: false,
  highLightEmail: false,
  highLightUserName: false,
  highLightPassword: false,
  highLightPasswordConf: false,
  highLightCompany: false,
  highLightGenre: false,
  highLightQuestions1: false,
  highLightanswer1: false,
  highLightQuestions2: false,
  highLightAnswer1: false,
  highLightAnswer2: false,
  passConfMessage: false,
  adminPage:false
};


function NewCustomerForm(props) {

    const [userInfo, setUserInfo] = useState({
      firstName : "",
      lastName  : "",
      email : "",
      userName : "",
      /*password : "",
      passwordConf : "",*/
      company : "",
      genre : "",
      questions1 : "",
      answer1 : "",
      questions2 : "",
      answer2 : "" 
    });
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [passConf, setPassConf] = useState("");
    const [company, setCompany] = useState("");
    const [genre, setGenre] = useState("");
    const [questions1, setQuestion1] = useState("0");
    const [ answer1, setAnswer1] = useState("");
    const [questions2, setQuestion2] = useState("0");
    const [answer2, setAnswer2] = useState("");
    const [validation, setValidation] = useState(true);
    const [emailInUse, setEmailInUse] = useState({account_created:false,
                                                  displayMessage:-1});

    const [reload, setReload] = useState(true);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);


    const showModal = () => {
       setShow(true);
     };
   
    const hideModal = () => {
       setShow(false);
     };

     const showModal2 = () => {
      setShow2(true);
    };
  
   const hideModal2 = () => {
      setShow2(false);
    };

//setUserInfo({...userInfo,firstName : "Kevin"});

    const [userResponse2, setUserResponse2] = useState("");
    const [DBUserResponse2, setDBUserRespnse2] = useState("");
    const [isHuman, setIsHuman] = useState([{sucess:false}]);
    const [messageState, setMessageState] = useState([]);
    const [messageSwitch, setMessageSwitch] = useState(false);
    const [formMessage, setFormMessage] = useState({});
    const [messageClass, setMessageClass] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [validateMessages, setValidateMessages] = useState([]);
    const humanKey = "03AGdBq27ifhFNzrc0LTneoKSFc5o-DhPyVQ2-2sBvAqazrCtleRi0KrC-MT-2BA5lRKDSmkhND6ex3qwERxADw38TB7tRKN8jLacJ3BGwB5nakDARn4q7XNxgoqgHeIsa0KdAJLyoCTz0BK4psPZv12bQWtjfGOFnAJuOh-F0TH0J9FRCXlT6-V0okIEiQ1yhlYfzmJG6q1n-uoKMf9xt9LrRRWbdSMxVHa__LMqZ2LQRwK2dfT9-S5LTB7hy3aNUTWzIwwpWxUoh5dglYu1LNn0NiCtuv9O3JkrE0uHlj08Ulnj-MsXGEUH6kRMKjqKhK7h0zulWe0rLdRIiWmHREm2GrhemSv9G3vFUCcKTZiwcLr3rlvt0YPww4p08lhZBbmGjIsRt15h8";
    const RECAPTCHA_SERVERs_KEY = "6LdAqMEZAAAAACflujHXyfDzXs7jVWSFb8LiQYRd";
    const SITE_KEY = "";
    const messageArray = [
                          "There was a problem with the Recaptcha response.",
                          "You must complete Recaptcha request.",
                          "You must enter a value in the text boxes.",
                          "Name values can not contain any numbers or special characters.",
                          "Passwords do not Match.",
                          "Registration Completed.",
                          "The email entered is already in use.",
                          "The user name you have chosen is not allowed.",
                          "You must check the terms and agreement and privacy check boxes befor the form is submitted.",
                          "We are unable to process you registration.  Please contact the system administrator."
                        ];
    const classType = {danger:"alert alert-danger alert-format", success:"alert alert-success alert-format"}
    const messageBlankText = "You must enter a value in the text box.";
    const emailInUseMessage = "The email You have entered is already being used.";
   // const [reload,setReload] = useState(true);

    var FName = "";
    var LName = "";
    var redEmail = "";
    var redUserName = "";
    var redPassword = "";
    var redPasswordConf = "";
    var redGenreProduced = "";
    var redAnswer1 = "";
    var redAnswer2 = "";
    var redCompanyName = "";
    var passMatch = true;

    var emailUsed = false;
    

  const resetForm = () =>{

    setFirstName("");
    setLastName("");
    setEmail("");
    setUserName("");
    setPassword("");
    setPassConf("");
    setCompany("");
    setGenre("");
    //setQuestion1("1");
    setAnswer1("");
    //setQuestion2("1");
    setAnswer2("");
    setFormMessage(initialState);
    setValidation(false);
    setEmailInUse({
                    account_created:false,
                    displayMessage:-1
                  })

  }

  
    const validate = () =>{

      setValidation(true);

      const renderFormMessage = {
        messageTextBox: "",
        messageNoNumbers: "",
        messagePassConf: "",
        messageUserNameNotAllowed: "",
        textBoxValidation: false,
        noNumbersValidation: false,
        passConfValidation: false,
        emailUsedValidation:false,
        userNameNotAllowed: false,
        highLightFName: false,
        highLightLName: false,
        highLightEmail: false,
        highLightUserName: false,
        highLightPassword: false,
        highLightPasswordConf: false,
        highLightCompany: false,
        highLightGenre: false,
        highLightQuestions1: false,
        highLightanswer1: false,
        highLightQuestions2: false,
        highLightAnswer1: false,
        highLightAnswer2: false,
        passConfMessage: false,
        messageEmailUsed:false,
        adminPage:false
      };

      const regName = /^[a-zA-Z]*$/;
      const regUserName = /^[6{3}]*[a-zA-Z]*6{3}[a-zA-Z]*$/;
      

      //console.log("textbox = "+firstName);
      if(firstName == ""){
        renderFormMessage.highLightFName = true;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }
      else if(!regName.test(firstName)){  
        renderFormMessage.highLightFName = true;
        renderFormMessage.messageNoNumbers = messageArray[3];
        renderFormMessage.noNumbersValidation = true;
      }

      if(lastName == ""){
        renderFormMessage.highLightLName = true;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }
      else if(!regName.test(lastName)){  
        renderFormMessage.highLightLName = true;
        renderFormMessage.messageNoNumbers = messageArray[3];
        renderFormMessage.noNumbersValidation = true;
      }

      if(userName == ""){
        renderFormMessage.highLightUserName = true;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }
      else if(regUserName.test(userName)){  
        renderFormMessage.highLightUserName = true;
        renderFormMessage.messageUserNameNotAllowed = messageArray[7];
        renderFormMessage.userNameNotAllowed = true;
      }

      if(email == ""){
        renderFormMessage.highLightEmail = true;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }

     /* if(userName == ""){
        renderFormMessage.highLightEmail = true;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }*/

      if(password == ""){
        renderFormMessage.highLightPassword = true;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }
     /* else if(!regUserName.test(password)){  
        renderFormMessage.highLightPassword = true;
        renderFormMessage.userNameNotAllowed = messageArray[7];
        renderFormMessage.noNumbersValidation = true;
      }*/

      if(passConf == ""){
        renderFormMessage.highLightPasswordConf = true;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }

      if(password != passConf){
        renderFormMessage.highLightPassword = true;
        renderFormMessage.highLightPasswordConf = true;
        renderFormMessage.messagePassConf = messageArray[4];
        renderFormMessage.passConfValidation = true;
      }

      if(answer1 == ""){
        renderFormMessage.highLightanswer1 = false;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }

      if(answer2 == ""){
        renderFormMessage.highLightAnswer2 = false;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }

      if(genre == ""){
        renderFormMessage.highLightGenre = true;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }

      if(company == ""){
        renderFormMessage.highLightCompany = true;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }

      if(answer1 == ""){
        renderFormMessage.highLightAnswer1 = true;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }

      if(answer2 == ""){
        renderFormMessage.highLightAnswer2 = true;
        renderFormMessage.messageTextBox = messageArray[2];
        renderFormMessage.textBoxValidation = true;
      }

   if(renderFormMessage.messageTextBox == "" && renderFormMessage.messageNoNumbers == "" && renderFormMessage.messagePassConf == "" && renderFormMessage.messageUserNameNotAllowed == ""){
        return false;
      }
  /* else if(renderFormMessage.messageBlank.length < 0){

     renderFormMessage.adminPage = true;
     return false;
   }*/
      else{

        console.log(renderFormMessage);
      setFormMessage(renderFormMessage);
      return true;
    }
  }// end validate

 // setValidateMessages(formMessages);

    const setMessageData = (messageNum,recap,classT)=>{
      let response = [{
        message: messageArray[messageNum],
        recaptchaMessage: recap,
        classType : classType.danger
      }];
      return response;
    }//end setMessageData

    const createUser = (user) =>{
      alert("in createUser function");
      const data = JSON.stringify(user);
      axios.post("api/CreateUserController.php", data).then(
        response=>{setEmailInUse(response.data)}).catch(
          err=>{/*console.log(err)*/})
    }//end createUser

    function onChange(value) {
      const data = [
        {
          serverKey : RECAPTCHA_SERVERs_KEY,
          response : value,
          siteKey : "6LdAqMEZAAAAAGPT45HFPid4UggIqxgmMMAyiaib"
        }
      ];
      axios.post('api/recaptcha',data).then(
        response=>{setIsHuman(response.data)}).catch(
          err=>{console.log(err)});
      }//end onChange

    const handleOnSubmit = (event)=>{
      event.preventDefault();
      let emailCheck = (document.getElementById("defaultCheck1").checked);
      let termsCheck = document.getElementById("defaultCheck2").checked;
      let privacyCheck = document.getElementById("defaultCheck3").checked;
      
      if(termsCheck == false || privacyCheck == false){
        alert("isHuman is false.");
        let messageData = setMessageData(8,true,0);
        setMessageSwitch(messageData[0].recaptchaMessage); 
        setErrorMessage(messageData[0].message);
        setMessageClass(messageData[0].classType);
      } 
      else if(userResponse2 != DBUserResponse2){
        alert("isHuman is false.");
        let messageData = setMessageData(0,true,0);
        setMessageSwitch(messageData[0].recaptchaMessage); 
        setErrorMessage(messageData[0].message);
        setMessageClass(messageData[0].classType);
      }
      else{
        console.log(validate());
         let message = validate();
        
        if(message)
        {
          console.log(formMessage);
        }
        else{

          let emailListresponse = "no";

                if(emailCheck){
                  emailListresponse = "yes";
                }

          const user = {
            firstName,      
            lastName,
            email,
            userName,
            password,
            passConf,
            company,
            genre,
            questions1,
            answer1,
            questions2,
            answer2,
            emailList:emailListresponse
          }

          alert("in else block");
          createUser(user);
          if(emailInUse.displayMessage != 0){
          resetForm();
          setMessageSwitch(false);
          }
          //console.log("Registration Completed.");  
        }
      }//end if statement
  }//end handleOnSubmit



    const handleOnChange = (event)=>{
      //console.log(event.target.value);

      /*var firstName = "";
      let lastName = "";
      let email = "";
      let userName = "";
      let password = "";
      let passwordConf = "";
      let company = "";
      let genre = "";
      let questions1 = "";
      let answer1 = "";
      let questions2 = "";
      let answer2 = "";*/

     

      if(event.target.name == "firstName"){
        setFirstName(event.target.value);
      };
      if(event.target.name == "lastName"){
        setLastName(event.target.value)
      };
      if(event.target.name == "email"){
        setEmail(event.target.value); 
      };
      if(event.target.name == "userName"){
        setUserName(event.target.value); 
      };
      if(event.target.name == "password"){
        setPassword(event.target.value);
      };
      if(event.target.name == "passwordConf"){
       setPassConf(event.target.value);
      };

      /*if(password.length > 8)
      {
        passLength = true;
      }*/

      if(event.target.name == "companyName"){
        setCompany(event.target.value); 
      };
      if(event.target.name == "genreProduced"){
        setGenre(event.target.value); 
      };
      if(event.target.name == "Questions1"){
        setQuestion1(event.target.value); 
      };
      if(event.target.name == "Questions2"){
        setQuestion2(event.target.value); 
      };
      if(event.target.name == "Answer1"){
        setAnswer1(event.target.value); 
      };
      if(event.target.name == "Answer2"){
        setAnswer2(event.target.value); 
      };
    
  } //end handleOnChange

  if(formMessage.highLightFName){
    FName = "red";
  }
  else{
    FName = "";
  }

  if(formMessage.highLightLName){
    LName = "red";
  }
  else{
    LName = "";
  }
  if(formMessage.highLightEmail || emailInUse.account_created){
    redEmail = "red";
  }
  else{
    redEmail = "";
  }
  if(formMessage.highLightUserName){
    redUserName = "red";
  }
  else{
    redUserName = "";
  }
  if(formMessage.highLightPassword){
    redPassword = "red";
  }
  else{
    redPassword = "";
  }
  if(formMessage.highLightPasswordConf){
    redPasswordConf = "red";
  }
  else{
    redPasswordConf = "";
  }
  if(formMessage.highLightCompany){
    redCompanyName = "red";
  }
  else{
    redCompanyName = "";
  }
  if(formMessage.highLightGenre){
    redGenreProduced = "red";
  }
  else{
    redGenreProduced = "";
  }
  if(formMessage.highLightAnswer1){
    redAnswer1 = "red";
  }
  else{
    redAnswer1 = "";
  }

  if(formMessage.highLightAnswer2){
    redAnswer2 = "red";
  }
  else{
    redAnswer2 = "";
  }

      if(password != passConf){
        passMatch = false;
      }

      if(emailInUse.account_created == false && emailInUse.displayMessage == 0){
      emailUsed = true;
  }

  var oops = false;
  if(emailInUse.account_created == false && emailInUse.displayMessage == 1){
    oops = true;
}


  const handleGetResponse = (userResponse,DBUserResponse) =>{
    console.log("in handleGetResponse");

    setUserResponse2(userResponse);
    setDBUserRespnse2(DBUserResponse);
    
  }
 

    return (
      
        <div>
          {show ? <TermsModal show={show} hideModal={hideModal}/> : ""}
          {show2 ? <PrivacyModal show2={show2} hideModal2={hideModal2}/> : ""}
          <NavBar/>
            <div className="product-container-newCustomer">
           
            <div className="web-text-newCustomer">
            
           
             <form onSubmit={handleOnSubmit}>
             <div className="formDiv-newCustomer message-format">
              {console.log(formMessage.textBoxValidation)}
              {console.log(formMessage.noNumbersValidation)}
              {console.log(formMessage.passConfValidation)}
              {console.log(formMessage.userNameNotAllowed)}
            {emailUsed ? <div className={classType.danger} role="alert">The email entered is already being used</div> : ""}
            {oops ? <div className={classType.danger} role="alert">{messageArray[9]}</div> : ""}
            {formMessage.textBoxValidation ? <div className={classType.danger} role="alert">{formMessage.messageTextBox}</div> : ""}
            {formMessage.noNumbersValidation ? <div className={classType.danger} role="alert">{formMessage.messageNoNumbers}</div> : ""}
            {formMessage.passConfValidation ? <div className={classType.danger} role="alert">{formMessage.messagePassConf}</div> : ""}
            {formMessage.userNameNotAllowed ? <div className={classType.danger} role="alert">{formMessage.messageUserNameNotAllowed}</div> : ""}
            {!validation && !emailUsed ? <div className={classType.success} role="alert">{messageArray[5]}</div> : ""}
            <label>First Name:</label> 
            <input type="text" name="firstName" value={firstName} onChange={handleOnChange} className={"form-control "+FName} pattern="[a-zA-Z]+" placeholder="Enter First Name" title="First Name must not contain any numbers or special characters."/>
            <label>Last Name:</label>
            <input type="text" name="lastName" value={lastName} onChange={handleOnChange} className={"form-control "+LName} pattern="[a-zA-Z]+" placeholder="Enter Last Name" title="Last Name must not contain any numbers or special characters." />
            <label>Email:</label>
            <input type="email" name="email" value={email} onChange={handleOnChange}  className={"form-control "+ redEmail} placeholder="Enter Email Address"/>
            <label>User Name:</label>
            <input type="text" name="userName" value={userName}  onChange={handleOnChange} className={"form-control "+ redUserName}  pattern="[a-zA-Z0-9]+" placeholder="Enter User Name" title="You must use only letters or numbers for the user name." />
            <label>Password:</label>
            <div className="text-div"><input type="password" name="password" value={password} onChange={handleOnChange}  className={"form-control " + redPassword + " textbox-format"} 
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" placeholder="Enter Password" title="Password must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"/></div><div className="passMatch">{passMatch ? <i class="green fas fa-check"></i>
:<i class="redCross fas fa-times"></i>}</div>
            <label>Password Confirmation:</label>
            <div className="text-div"><input type="password" name="passwordConf" value={passConf} onChange={handleOnChange}  className={"form-control "+redPasswordConf+ " textbox-format"} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" placeholder="Enter Password Confirmatiion" title="Password must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"/></div><div className="passMatch">{passMatch ? <i class="green fas fa-check"></i>
:<i class="redCross fas fa-times"></i>}</div>
            <label>Company Name:</label>
            <input type="text" name="companyName" value={company} onChange={handleOnChange}  className={"form-control "+redCompanyName} placeholder="Enter Company Name" />
            <label>Genre Produced:</label>
            <input type="text" name="genreProduced" value={genre} onChange={handleOnChange}  className={"form-control "+redGenreProduced} placeholder="Enter Genre" />
            <label className="label-format">Security Question 1: </label>
            <select className="form-control"  onChange={handleOnChange} name="Questions1"  id="questions1">
      <option value="0">What was the make of your first car?</option>
      <option value="1">What is the nickname of the high school you went to?</option>
      <option value="2">What city were you born in?</option>
      <option value="3">What is the name of your favorite teacher?</option>
      <option value="4">What was the make of your first computer?</option>
      <option value="5">What is your mother\'s madien name?</option>
      <option value="6">What is your father\'s middle name?</option>
      <option value="7">Who is your favorite Hip Hop artist?</option>
      <option value="8">What is the name of the first city you have vistied?</option>
      <option value="9">What is the name of your favorite Hip Hop song?</option>
    </select><br/>
    <input type="text" className={"form-control "+redAnswer1} id="answer1" value={answer1} onChange={handleOnChange}  name="Answer1" placeholder="Enter Security Question1 Answer"></input>
    <label>Security Question 2 </label>
    <select className="form-control" onChange={handleOnChange}  name="Questions2"  id="questions2">
      <option value="0">What was the make of your first car?</option>
      <option value="1">What is the nickname of the high school you went to?</option>
      <option value="2">What city were you born in?</option>
      <option value="3">What is the name of your favorite teacher?</option>
      <option value="4">What was the make of your first computer?</option>
      <option value="5">What is your mother\'s madien name?</option>
      <option value="6">What is your father\'s middle name?</option>
      <option value="7">Who is your favorite Hip Hop artist?</option>
      <option value="8">What is the name of the first country you have vistied?</option>
      <option value="9">What is the name of your favorite Hip Hop song?</option>
    </select><br/>
    <input type="text" className={"form-control "+redAnswer2} id="answer2" value={answer2} onChange={handleOnChange}  name="Answer2" placeholder="Enter Security Question2 Answer"></input>
    <br/>
    <div className="checkbox-format">
    <div className="checkbox-div"><input className="form-control registration-checkbox-newCustomer"  type="checkbox" name="Mailing_List1" id="defaultCheck1" value="yes"/></div><label className="checkbox-text">Check to receive promotional emails.</label></div>
  
    <div className="checkbox-format">
    <div className="checkbox-div"><input className="form-control registration-checkbox-newCustomer"  type="checkbox" name="Mailing_List2" id="defaultCheck2" value="yes"/></div><label className="checkbox-text">Check to accept <span className="terms_agreement" onClick={showModal}><u>Terms and Agreement.</u></span></label></div>
  
    <div className="checkbox-format">
           <div className="checkbox-div"><input className="form-control registration-checkbox-newCustomer"  type="checkbox" name="Mailing_List3" id="defaultCheck3" value="yes"/></div><label className="checkbox-text">Check to accpet <span className="terms_agreement" onClick={showModal2}><u>Privacy Policy.</u></span></label></div>
           <br/>
            
            
            </div>
            <br/>
            {messageSwitch ? <div className={messageClass} role="alert">{errorMessage}</div> : ""}
            <Captcha handleGetResponse={handleGetResponse}  reload={reload}/> 
            </form> 
           
            </div>
            
          </div>
          </div>  
    );
}

export default NewCustomerForm;