import React, {useState, useEffect} from 'react';
import '../css/purchased_Products.css';



function UserItems(props) {
//*******old code**************************/
    /*console.log(props);

    const [isSelected, setIsSelected] = useState(false);
    const [count, setCount] = useState(0);
    var widthCalc = (count*43);

    function playMusic(Selected)
    {
        setIsSelected(!Selected)
    }*/

    const urlItems = "/api/UserItemsController.php";
    

    const [isSelected, setIsSelected] = useState(false);
    const [audioObject, setAudioObject] = useState(null);
const [song, setSong] = useState({});
const [time, setTime] = useState(0.0);
const [style, setStyle] = useState({});

function playMusic(Selected)
{
    setIsSelected(!Selected)
}

var activeSong;
 const play = ()=>{
     audioObject.play();
     setIsSelected(true);
 }

 const checkUpdate = (event) =>{
    //console.log(event.srcElement.currentTime);
    
    var progression = (audioObject.currentTime/audioObject.duration) * 100;
    console.log(progression);
    
    setTime(progression);
    if(audioObject.currentTime == audioObject.duration){
        event.srcElement.currentTime = 0;
        audioObject.pause();
        setIsSelected(false);
    }
}

if(audioObject != null){
audioObject.addEventListener('timeupdate',checkUpdate);
}



 const stop = ()=>{

        audioObject.pause();
     setIsSelected(false);
 }

 console.log(time);

 const changeVolume = (number, direction) =>{
     var volume = song.active;
     if(audioObject.volume < 1 && direction == "up"){
         audioObject.volume = audioObject.volume + (number/100);
     }

     if(audioObject.volume > 0 && direction == "down"){
        audioObject.volume = audioObject.volume - (number/100);
    }
 }

 var progress = {
    width:Math.ceil(time).toString()+"%",
    backgroundColor:"white",
    height:"2px;"
}


 const adjustCatalog = {
     fontSize:"14px",
     height:"45px",
     padding:"8px"
 }

 useEffect(()=>{
    const demoUrl = "Demo/"+props.demoName;
    const audio = new Audio(demoUrl);
    setAudioObject(audio);
},[]
);

var white = {color:"white"}
    return (
        <div >
                    <div key={props.key} className="product-div-purchase">
                    <div className="product-info-div-purchase">
                            <h2 className="height-1 product-name" style={{color:"white", size:"7px"}}>{props.itemName}</h2>
                    </div>   
                    <div className="button-container-purchase">
                    {isSelected ? <div  className="button display" onClick={()=>stop()}><i className="fas fa-stop size-purchase"></i></div> : <div  className="button display" onClick={()=>play()}><i className="fas fa-play size-purchase"></i></div>}
                        <a href={props.url} download><div className="button display marginLeft">Download</div></a>
                    </div>   
                    <div className="progress-container-catalog">
                        <div className="progress" style={progress}></div>
                    </div>
                    <div className="chevron-div-purchase">
                        <p className="volume-control-purchase">VOLUME CONTROL</p>
                        <i class="fas fa-chevron-circle-up chevron-purchase" onClick={()=>changeVolume(10,"up")}></i>
                        <i class="fas fa-chevron-circle-down chevron-purchase" onClick={()=>changeVolume(10,"down")} style={{marginLeft:"50px"}}></i>
                    </div>     
                    </div>
                        
                {/*<div id="pageination-container" className="pagination-div-container" style={{width:widthCalc + 'px'}}>
				 item.map((pageNum)=>{return(<div id={"pageDiv"+pageNum.num} className={pageNum.isSelected ? "selected" : "pageDiv"} onClick={()=>handlePagination(pageNum.num)}>{pageNum.num+1}</div>)})
    </div> */}
                </div> 
    );
}


/*const mapStateToProps = (state) => {
    return {
        token: state.token,
        isAuth: state.isAuth,
    }}

    const mapDispatchToProps = (dispatch)=>{
        return{
        setAuth: () => (dispatch(setAuth(false)))
        }
        }

export default connect(mapStateToProps ,mapDispatchToProps)(UserItems);*/

export default UserItems;