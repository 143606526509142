import React, {useEffect, useState} from 'react';
import {Link,NavLink} from 'react-router-dom';
import $ from 'jquery';
import '../css/footer.css';

function Footer(props) {

  var port = "";
const[turnOn, setTurnOn]= useState(0);
const[ops,setOps] = useState("opacity")

const domain_name =  document.location.hostname;
const http_status = window.location.protocol;
//const url = http_status+"//"+domain_name+":3000";
/**production url */
//const url_apache = http_status+"//"+domain_name;
const url_apache = http_status+"//"+domain_name+":3001";




  useEffect(
    function(){
      setTurnOn(0);
      $(window).scroll(function(){
        $('.stripe-black').toggleClass('inStripeRight', $(this).scrollTop() > 414);
        $('.stripe-white').toggleClass('inStripeUp', $(this).scrollTop() > 414);
        if($(this).scrollTop() > 414){
          setTurnOn(1);
          setOps("");
        }
       
        
      });
    },[]);

    /*if(process.env.NODE_ENV == "development"){
      port = ":3000"
  }
  else{
      port = "";
  }*/

  port = "3000"

    return (
      <div className="footer">
       
          <div className={"stripe-black inStripeRight "+ops} style={{opacity:turnOn}}></div>
          <div className={"stripe-white inStripeUp "+ops}></div>
        
          <div className="container-fluid">
        	<div className="row">
            <div className="col col-lg-4 gone">
              <div className="imageFooterDiv"><img className="footerImage" src={"photos/footerImage2.jpg"} alt=""/></div>
                <h5 className="footer-subHeader">Site Map</h5>
                <br/>
                <div className="site-map-list">
                  <ul>
                   <li><NavLink to="/about_us" style={{textDecoration:"none",color:"white"}} acitveClassName="active-footer">About Us</NavLink></li>
                    <li><NavLink to="/contact_us" style={{textDecoration:"none",color:"white"}} acitveClassName="active-footer">Contact Us</NavLink></li>
                    <li><NavLink to="/free_beats" style={{textDecoration:"none",color:"white"}} acitveClassName="active-footer">Free Beats</NavLink></li>
                    <li><NavLink to="/free_loop_sets" style={{textDecoration:"none",color:"white"}} acitveClassName="active-footer">Free Loop Sets</NavLink></li>
                    <li><NavLink to="/free_sound_patches" style={{textDecoration:"none",color:"white"}} acitveClassName="active-footer">Free Sound Patches</NavLink></li>
                    <li><NavLink to="/catalog" style={{textDecoration:"none",color:"white"}} acitveClassName="active-footer">View Music Catalog</NavLink></li>
                    <li><NavLink to="/terms_agreement" style={{textDecoration:"none",color:"white"}} acitveClassName="active-footer">Terms &amp; Agreement</NavLink></li>
                    <li><NavLink to="/privacy_policy" style={{textDecoration:"none",color:"white"}} acitveClassName="active-footer">Privacy Policy</NavLink></li>
                    <li><NavLink to="/faq" style={{textDecoration:"none",color:"white"}} acitveClassName="active-footer">FAQ</NavLink></li>
                    <li><NavLink to="/showlove_blogs" style={{textDecoration:"none",color:"white"}} acitveClassName="active-footer">Blogs</NavLink></li>
                  </ul>
                </div>
            </div>
            <div className="col col-lg-4 gone">
            <div className="imageFooterDiv"><img className="footerImage" src={"photos/footerImage1.jpg"}  alt=""/></div>
              <h5 className="footer-subHeader">Contact Info</h5>
              <br/>
              <div className="contactFooterDiv">
              <p><span><i className="fas fa-map-marker"></i>  &nbsp; Toronto, Ontario, Canada</span></p>
               <p><span><i className="fas fa-phone"></i>  &nbsp;(416) 570-7647</span></p>
               <p><span><i className="fas fa-envelope"></i> &nbsp; <span className="email">ShowLove.Productions@gmail.com</span></span></p>
               </div>

            </div>
            <div className="col col-lg-4 gone">
            <div className="imageFooterDiv"><img className="footerImage" src={"photos/footerImage3.jpg"}  alt=""/></div>
              <h5 className="footer-subHeader">Mission Statement</h5>
              <br/>
              <div className="footer-mission-text">
              <p>Our mission is to provide high quality beats and loop sets to producers who are willing to use their music to promote a postive message within our communities.</p>

              <p>The Show Love Production Motto is: "For producers who think outside the box."</p>
              </div>
            </div>

          </div>
          </div> 
          <br/>
          <br/>
          <div className="socialDiv"><p>FOLLOW US:</p>
                <div><a href="https://www.facebook.com/ShowLoveProductions316/" style={{textDecoration:"none",color:"white"}}><span className="socialIcons"><i className="fab fa-facebook-square"></i></span></a><a href="https://www.youtube.com/channel/UCgu9DSZitTexeTooVCJNTDg" style={{textDecoration:"none",color:"white"}}><span className="socialIcons"><i className="fab fa-youtube"></i></span></a><a href="https://soundcloud.com/user-67288742" style={{textDecoration:"none",color:"white"}}><span className="socialIcons"><i className="fab fa-soundcloud"></i></span></a><a href="https://twitter.com/ShowLove316" style={{textDecoration:"none",color:"white"}}><span className="socialIcons"><i className="fab fa-twitter"></i></span></a></div> 
          </div>
          <br/>
          <h6 style={{textAlign:"center",color:"white",fontFamily:"Arial, Helvetica, sans-serif"}}>&copy;Copyright Show Love Productions 2021</h6>       
      </div>
	
    );
}

export default Footer;