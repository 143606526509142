import React from 'react'
import NavBar from './navBar';
import '../css/Is_HipHop_Dead.css';
import Wheres_The_Beef_Content from './Wheres_The_Beef_Content';


const Wheres_The_Beef = (props)=> {
    

    return (  
        <div>
            <div className="mic-background-image-HipHop">
            <NavBar/>
          <div>
          <div className="format-div-HipHop"></div>
          </div>
                <h1 className="catalog-title-HipHop">Show Love Blog</h1>
                <Wheres_The_Beef_Content/>
            </div>
        </div>
    );
}

export default Wheres_The_Beef;
