import React, {useState,useEffect} from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthentication } from "./cartSlice";
import NavBar from './navBar';
import UserItems from './UserItems';
import PageForbidden from './PageForBidden';
import '../css/purchased_Products.css';
import '../css/catalog.css';
import { useLoaderData } from 'react-router-dom';


const Purchased_Products = (props)=>{

//const response = useLoaderData();  

const setAuth = useDispatch();
const isAuth = useSelector((state) => state.cart.isAuth);


const [itemNameArray, setItemNameArray] = useState([]);
const [downloadLinksArray, setDownloadLinksArray] = useState([]);
const [demoNameArray, setDemoNameArray] = useState([]);
const [currentPage, setCurrentPage] = useState([]);



const [display, setDisplay] = useState(false);

const numberOfRows = 5
const numberOfPages = downloadLinksArray.length/numberOfRows;

const pages = [];
for(let i =0; i < numberOfPages; ++i)
{
    pages.push(i);
}
console.log(numberOfPages);
var widthCalc = Math.ceil(numberOfPages)*46;

const getPage = (event)=>{
    setCurrentPage(Number(event.target.id));
}

useEffect(()=>{
         const urlItems = "api/UserItemsController.php";
   
    axios.get(urlItems).then(
        (response)=>{
            if(response.data.result){
                setAuth(setAuthentication(response.data.result));
                setItemNameArray(response.data.itemName);
                setDownloadLinksArray(response.data.downloadLinks);
                setDemoNameArray(response.data.demoName);
               if(response.data.downloadLinks.length > 0){
                    setDisplay(true);
                }
                else
                {
                    setDisplay(false);
                }
            
            }   
        }).catch((error)=>{
            //console.log(error)      
    })
 
},[]);
                
  let linkFormat = [];
 
    
   
        var offset = numberOfRows * currentPage;
        var lastRow = offset + numberOfRows;
        var lastIndex = downloadLinksArray.length;
       // console.log(currentPage);
        if(downloadLinksArray.length < numberOfRows){
            linkFormat = downloadLinksArray.slice(0,lastIndex);
        }
        else if(currentPage == 0){
            linkFormat = downloadLinksArray.slice(offset,lastRow);
        }
        else{
            linkFormat = downloadLinksArray.slice(offset,lastRow+1);
        }
    
    const style = {
        height:"max-content"
    }

    const style2 = {
        height:"100vh"
    }

    const paginationContainer = {
        width: widthCalc + "px",
        margin:"auto"
    }

    

    
    return(
        <div className="mic-background-image-purchase" style={display && isAuth ? style:style2}>
            <NavBar/>
            <div className="format-div-purchase"></div>
            <div className="catalog-title-purchase"><h1>Purchased Products</h1></div>
            {!isAuth ? <div className="purchased-items-message">You must be logged in to view this page.</div> :
            <div className="product-container-catalog">
              
             {display ? linkFormat.map((links)=> 
                                                <UserItems
                                                      key={links.id}
                                            itemName={itemNameArray[links.id]}
                                            demoName={demoNameArray[links.id]}
                                            url={links.url}
                                        /> ):<div className="purchased-items-message">You have no purchased items yet.</div>}
       <br/>
         {console.log(widthCalc)}
         <div id="pageination-container" className="pagination-div-container" style={{width:widthCalc + 'px'}}>
                        {pages.map((index)=><div id={index} className={currentPage == index ? "selected" : "pageDiv"} onClick={getPage}>{index}</div>)}
                        </div>
                        </div>}
        </div>
    );
}




export default Purchased_Products;
