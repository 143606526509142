import React from 'react';
import '../css/about_us.css';
import '../css/product.css';


function About_Us(props) {

    return (
        <div className="product-container-about text-style-about">
            <div className="web-text-about">
            <p>I am a former student of the Computer Music Production School which is an online music production school.</p>

<p>My company's name is Show Love Productions and is located in Toronto, Canada, Ontario. We sell urban genre multi-track loops and instrumentals. We will also be selling software synth music patches and drum samples in the near future as well as DJ and mastering speaker monitors.</p>

{/*<p>Our e-commerce website allows you to purchase individual loops and create your own loop package oppose to having to buy a predetermined one. The loops are emailed to the customer for instant download.</p>*/}

<p>Our slogan is "Show Love Productions, for people who think outside the box." Our mission statement is "To promote a positive attitude in the urban music culture so that people who listen to urban music will be encourage to display a positive attitude.”</p>

<p>Our goal is to bring a positive attitude to the urban music scene by producing and encouraging other producers to create music that will bring a positive vibe to everyone who listens.</p>

<p>As you may well know, today’s urban music scene is plagued with music that promotes negativity such as guns, drugs, alcoholism and bad treatment toward women.</p>

<p>Show Love Productions hopes to change this downward spiral by making our music only available to those who agree to use the music in a positive way.</p>

<p>Although I am a Christian, producers who chose to use our music do not have to promote Christianity, they only must agree not to disrespect any one or any living thing.</p>

<p>This leaves a wide range of topics to sing and rap about and we at Show Love Productions feel that we can be successful and bring a positive vibe back to the urban music scene.</p>

<p>Example of past urban music positive entertainers would be KRS-1 (Hip Hop) and Bob Marley (Reggae). These artists' names are well known in and out of their respective musical genres.</p>
</div>
        </div>
    );
}

export default About_Us;