import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import NavBar from './navBar';
import '../css/blogMenu.css';

function BlogMenu(props) {

    const blogTitles = [
        {
            id:1,
            title:"Is Hip Hop Dead?",
            author:"Kevin Haynes",
            year:"2017",
            uri:"/is_hiphop_dead"
        },
        {
            id:2,
            title:"Where's the Beef?",
            autho:"Kevin Haynes",
            year:"2018",
            uri:"/wheres_the_beef"
        }
    ];
        
    return (  
        
            <div className="mic-background-image-blogMenu">
            <NavBar/>
          <div>
          <div className="format-div-blogMenu"></div>
          </div>
                <h1 className="catalog-title-blogMenu">Show Love Blogs</h1>
                <div className="product-container-customerAccount about-us-style">
                     <div className="account-div">
                {blogTitles.map((blog)=>
                            
                     <Link to={blog.uri} className="nav-bar-links" style={{textDecoration:"none"}}><div className="account-text">{blog.title}</div></Link>
                        )}
         
            </div>
        </div>
        </div>
    );
}
export default BlogMenu;