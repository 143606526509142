import React from 'react';
import '../css/orderConfirmatin.css';

function OrderConfContent(props) {
    return (
        <div className="product-container-confirmation about-us-style">
            <div className="web-text-confirmation">
            <h1 style={{textAlign:"center"}}>Thank You for Shopping with<br/> Show Love Productions</h1>

<p>An Email has been sent to your registered email address that contains your order information and a download link to your purcahsed items.</p>
<p>If you do not receive an email within 30 minutes of your order, please contact ShowLove.Productions@gmail.com to rectify any problems.</p>
<p>Also, Please check your spam box to make sure that your order was not marked as spam.</p>
<p>We hope to business with you again.</p>
</div>
        </div>
    );
}

export default OrderConfContent;