import React, {useState} from 'react';
import { withRouter } from 'react-router';

function Term_Agreement_Text(props) {

    const [format, setFormat] = useState({});

    return (
        <div className="product-container-about text-style-terms">
            <div className="web-text-terms">
            <p><b>This License Agreement is effective as of January 1st, 2017</b> and is an agreement between YOUR NAME HERE <em>(now on referred to as the License Purchaser)</em> and Show Love Productions\' owner who is known as Kevin Haynes <em>(Beat Composer)</em>.  Show Love Productions grants 0% of the publishing rights to the customer and will retain ownership of the composition Licensed to the License Purchaser.</p> 
<br/>  
<p><b>Composition Usage Conditions.</b>  Show Love Productions grants the rights to an unlimited number of performances, Broadcasts, Master Recording Creations, and Song Distributions containing Show Love Production composition providing The License Purchaser uses the composition in a respectful manner without glorification of drugs, violence, abusive treatment towards women, and the disrespecting of one’s belief or religion.  The License Purchaser also agrees not to create songs that contain threats or intentions to damage or harm anyone or their possessions.  Should the License Purchaser violate the composition usage conditions stated in this Terms and Agreement any song composed, produced, or being sold containing Show Love Productions composition will have Show Love Productions’ part in the License Purchaser’s composition or song removed immediately.  All compositions which contain Show Love Productions’ composition will no longer be royalty free and the License Purchaser will have to pay Show Love Productions Royalties from, Mechanical distributions, Performances, Blanket Licenses, publishing deals, touring, or any other income that Show Love Productions’ composition took part in generating.</p>  
<br/>  
<p><b>Credit</b>  The License Purchaser shall give acknowledgement on all works containing Show Love Productions’ compositions.  All acknowledgement should go under the name “Show Love Productions.”</p> 
<br/>
<p><b>Audio Samples.</b>  3rd party sample clearance is the responsibility of the License Purchaser.</p>
<br/>
<p><b>Governing Law.</b>  This License is governed by all applicable laws under the Canadian Government and without regard to conflicts of laws principles thereof.</p>
<br/>
<p className="terms_agreement-text"><b>All licenses are non-refundable and non-transferable.</b></p>

        </div>
        </div>
    );
}

export default Term_Agreement_Text;