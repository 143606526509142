import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCirclePlay} from '@fortawesome/free-solid-svg-icons';
import {faCirclePause} from '@fortawesome/free-solid-svg-icons';



const Tracks = (props) =>{

    const [songLocation, setSongLocation] = useState("");
    console.log(props.playerOn);

    
    const [isPlaying,setIsPlaying] = useState(false);
    const [audioObject,setAudioObject] = useState(null);
    var iconArray = [faCirclePlay];

    const [icon, setIcon] = useState(null);

    const playTrack = ()=>{
        var audio = audioObject;
        if(isPlaying){
            audio.pause();
            setIcon(<FontAwesomeIcon icon={faCirclePause} className="icon-format" onClick={()=>playTrack()}/>);
            setIsPlaying(false);
        }
        else{
        audio.play();
        setIcon(<FontAwesomeIcon icon={faCirclePlay} className="icon-format" onClick={()=>playTrack()}/>);
        setIsPlaying(true);
        }
    }

    const checkUpdate = (event) =>{

        if(audioObject.currentTime == audioObject.duration){
            audioObject.currentTime = 0;
            audioObject.pause();
            setIsPlaying(false);
            setIcon(<FontAwesomeIcon icon={faCirclePlay} className="icon-format" onClick={()=>playTrack()}/>);
        }
    }

    const endAudio = (event)=>{
        event.srcElement.pause();
        setIsPlaying(false);
    }
    
    if(audioObject != null){
      var audio2 = audioObject;
      audio2.addEventListener('timeupdate',checkUpdate);
    }
   
    useEffect(
        ()=>{
            const url = "Music/"+props.songUrl+".mp3";
            const audio = new Audio(url);
             setAudioObject(audio);

        },[]
    )

    return(
        <div>
            <div className="track-row" key={props.key}>
           {isPlaying ? <FontAwesomeIcon icon={faCirclePause} className="icon-format" onClick={()=>playTrack()}/> : <FontAwesomeIcon icon={faCirclePlay} className="icon-format" onClick={()=>playTrack()}/> }
           <span className="format-playlist-text">{props.songName}</span>
            </div>
        </div>
    )
}

export default Tracks;