import {createBrowserRouter} from 'react-router-dom';
import {useState} from 'react';
import axios from 'axios';
import Home from './home';
import Catalog from './Catalog';
import Cart from './cart';
import AboutUs from './About_Us';
import NavBar from './navBar';
import Footer from './Footer';
import TermsAgreement from './Terms_Agreement';
import '../css/product.css';
import Registration from './Registration';
import CustomerAccount from './CustomerAccount';
import ResetPassword from './ResetPassword';
import Test_Link from './TestLink';
import ContactUs from './ContactUs';
import App from './app';
import Create_Password from "./Create_Password";
import FreeSounds from './FreeSounds';
import PageNotFound from './PageNotFound';
import PersonalInfo from './PersonalInfo';
import ViewPersonalInfo from './ViewPersonalInfo';
import Purchased_Products from './Purchased_Products';
import OrderConfirmation from './OrderConfirmation';
import FAQ from './FAQ';
import ChangePassword from './ChangePassword';
import Is_HipHop_Dead from './Is_HipHop_Dead';
import Wheres_The_Beef from './Wheres_The_Beef';
import BlogMenu from './BlogMenu';
import PrivacyPolicy from './PrivacyPolicy';
import FreeSoundPatch from './FreeSoundPatch';
import FreeBeats from './FreeBeats';
import FreeLoops from './FreeLoops';
import CookieTest from './cookieTest';
import {loader as catalogLoader} from './loader';                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
import { itemsLoader as purchasedProductsLoader} from './itemsLoader';    
import {ChangePasswordLoader as changeLoader} from './ChangePasswordLoader'; 
import { PersonalInfoLoader as infoLoader } from './PersonalInfoLoader';                                         


const RouterPaths = () =>{

    const router = createBrowserRouter([   
        {
            element:<App/>,
            errorElement:<PageNotFound/>,
        children: [
        {
            element:<Home/>,
            path:"/",
           
        },
        {
            path:"customer_account",
            element:<CustomerAccount/>
           
        },
        {
            path:"about_us",
            element: <AboutUs/>
        },
        {
            path:"contact_us",
            element:<ContactUs/>
        },
        {
            path:"registration",
            element:<Registration/>
        },
        {
            path:"terms_agreement",
            element:<TermsAgreement/>
        },
        {
            path:"privacy_policy",
            element:<PrivacyPolicy/>
        },
        {
            path:"free_sounds",
            element:<FreeSounds/>
        },
        {
            path:"free_beats",
            element:<FreeBeats/>
        },
        {
            path:"free_loop_sets",
            element:<FreeLoops/>
        },
        {
            path:"free_sound_patches",
            element:<FreeSoundPatch/>
        },
        {
            path:"reset_password",
            element:<ResetPassword/>
        },
        {
            path:"edit_personal_info",
            element:<PersonalInfo/>
        },
        {
            path:"view_personal_info",
            element:<ViewPersonalInfo/>
        },  
        {
            path:"create_password",
            element:<Create_Password/>
        },
        {
            path:"purchased_products",
            element:<Purchased_Products/>
        },
        {
            path:"change_password",
            element:<ChangePassword/>
        },
        {
            path:"catalog",
            loader:()=>catalogLoader(),
            element:<Catalog/>,
        },
        {
            path:"order_confirmation",
            element:<OrderConfirmation/>
        },
        {
            path:"faq",
            element:<FAQ/>
        },
        {
            path:"showlove_blogs",
            element:<BlogMenu/>
        },
        {
            path:"is_hiphop_dead",
            element:<Is_HipHop_Dead/>
        },
        {
            path:"wheres_the_beef",
            element:<Wheres_The_Beef/>
        }
    ]
},
{
    path:"cart",
    element:<Cart/>
},
    ]);
       


    return router;
}

export default RouterPaths;


