import React, {useEffect} from 'react';
import NavBar from './navBar';
import Faq_Content from "./Faq_Content";
import AboutUsContent from './About_Us_Content';
import '../css/faq.css';

function FAQ(props) {
        
    return (  
        <div>
            <div className="mic-background-image-faq">
            <NavBar/>
          <div>
          <div className="format-div-faq"></div>
          </div>
                <h1 className="catalog-title-faq">Frequently Asked Questions</h1>
                <Faq_Content/>
            </div>
        </div>
    );
}
export default FAQ;