import React, {useState, useEffect} from 'react';
import axios from "axios";
import HelpModal from "./HelpModal";
import '../css/captchaImage.css';

function Captcha(props) {
    

    
    const [userResponse, setUserResponse] = useState("");
    const [captchaIndex, setCaptchaIndex] = useState(0);
    const[DBUserResponse, setDBUserResponse] = useState("");
    const [buttonColor, setButtonColor] = useState("helpIcon_blue.png")
    const [message, setMessage] = useState("");
    const [show, setShow] = useState(false);
    const urlGet = process.env.REACT_APP_API_PATH+"ContactUsController.php";
    const urlGet2 = "api/ContactUsController.php";

    const handleOnChange = (e) =>{
        setUserResponse(e.target.value);
    }

    useEffect(
		()=>{
			axios.get(urlGet2).then((response)=>{
                {   
                    setDBUserResponse(response.data.captchaResponse);
                    setCaptchaIndex(response.data.index);
                }  
            }).catch((error)=>{
                //console.log(error);
            });
		},[props.reload]
	);
   
    const showModal = () =>{
        setShow(true);
    }

    const hideModal = () =>{
        setShow(false);
    }

    const changeButton = () =>{
        setButtonColor("helpIcon_white.png");
    }

    const buttonReturn = () =>{
        setButtonColor("helpIcon_blue.png");
    }

    return (
        <div>
            {show ? <HelpModal show={show} hideModal={hideModal}/> : ""}
            <p className="captcha-text">Enter the <i><b><u>ALPHABETICAL LETTERS  ONLY</u></b></i> in the image</p>
            <p className="captcha-text"><i><b>(case sensitive)</b></i><img class="help-button" onMouseLeave={()=>buttonReturn()} onMouseEnter={()=>changeButton()} onClick={()=>showModal()}src={"Help_Icons/"+buttonColor}/></p>
            <div className="captcha-imageDiv">
                <img src={"Captcha_Images/image"+captchaIndex+".jpg"} className="captcha-image"/>
            </div>
            <div className="captcha-textboxDiv">
                <label>Captcha Response</label>
                <input type="text" name="captchaResponse" value={userResponse}  onChange={handleOnChange} className="form-control" placeholder="Enter Captcha Response" title="Enter only the letters in the captcha image (case sensitive)." />
                       
            </div>
            <div className="button-div"><button className="submit-button captchaButton-format captchaButton-position1" type="submit" value="Submit" onClick={()=>props.handleGetResponse(userResponse,DBUserResponse)}>SUBMIT</button><div className="submit-button captchaButton-format captchaButton-position2" onClick={()=> window.location.reload(false)}>REFRESH</div></div>
            <input type="hidden" value={props.reload}/>
        </div>
    );
}

export default Captcha; 