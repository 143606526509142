import React from 'react';
import '../css/Is_HipHop_Dead.css';

const Is_HipHop_Dead_Content = () =>{

    const style = {
        textAlign:"center"
    }

    return(
        <div className="product-container-HipHop">
            <div className="web-text-HipHop">
            <h2 style={style}>Is Hip Hop Dead?</h2>
		<h3 style={style}>by Kevin (Truck) Haynes</h3>
        
<p>If you are like me and have been listening to Hip Hop from the golden age until now, you have seen Hip Hop rise with artists being aware of their social surroundings.  This was evident when the Hip Hop community came together with the classic track Self Destruction.  You would also have witness the descent of Hip Hop as more and more artists glorify the “Thug Life” and the pursuit of material things rather than True happiness.</p> 
<p>Although early Hip Hop was not innocent, artists did not neglect their social responsibilities and ties to their neighborhoods.  Classic tracks like the one from Grandmaster Flash and the Furious Five, “The Message” used Hip Hop as a medium to show some of the struggles that the urban youth had to endure.  A few years later, Public Enemy came along and were Hip Hop social advocates. They were not afraid to let the world know their views on the social injustice that they both witnessed in the past and were seeing in the present.  Other artist like KRS-1 made his whole career synonymous with consciousness of self and the promotion of a positive attitude.  The list goes on.</p>
<p>So, what happened?  Why have we gone from being aware of our social surroundings to accepting defeat by pursuing material possessions that will never gain us true happens.  Think about it, how much burgatties have you seen in your hood?  Realistically, you will not see much if any.  Yet, upcoming rappers, especially young ones, tend to steer their listeners toward getting these material things.  And how is getting these things promoted in their songs?  Nine times out of ten it is by illegal activity, practically the selling of drugs.  It boggles my mind how so much mordern Hip Hop songs talk about how nice getting illegal money is, but fail to leave out the other stuff.  What other stuff you ask?  Jail time, Homies dying, Mothers and Fathers crying, Parentless children, and the negative stigma that one gets after doing jail time. And that is just to name a few things.</p>
<p>Although they are becoming harder to find, there are artists who seem to be aware of their social surroundings.  These artists are usually underground rappers. Also, artists who promote Jesus as the Son of God such as those of Reach Records, God Over Money, and Cross Movement Records have realesed Hip Hop songs with a strong message and something that has not been too synonymous with hip hop before, Hope.</p>
<p>So, is Hip Hop Dead?  I don 't know if Hip Hop is dead. But, until this and the next generation of Hip Hop artists can start rapping about what is really going on in the Hood and stop pushing the Thug Fantasy that will make them money, not only will we see the death of Hip Hop but, also the death of a lot of the youth who listen to Hip Hop music.</p>

</div>
        </div>
    );
}

export default Is_HipHop_Dead_Content;