import React,{ useState } from 'react';
import NavBar from './navBar';
import axios from 'axios';
import '../css/resetPassword.css';

function ResetPassword(props) {

  const userMessage = [
    "Enter your email address",
    "An Email link will be sent to you if your email is in our system"
   ];

   const alertClass = {
      alert1:"alert",
      alert2:"alert-success"
   };

const [ email, setEmail ] = useState("");
const [ password, setPassword ] = useState("");
const [ passwordConf, setPasswordConf ] = useState("");
const [resetRequest, setResetRequest ] = useState(false);
const [message, setMessage] = useState(userMessage[0]);
const [loading, setLoading] = useState(false);
const [alert, setAlert] = useState({alert1:"", alert2:""});
const [style,setStyle] = useState({color:"black"});


const url = "api/ResetEmailController.php";


const handleSubmit = () =>{
  setLoading(true);
    const userInfo = {
        email,
        resetRequest:true
    };
    console.log(userInfo);

    axios.post(url,userInfo).then((response)=>{
        console.log(response.data);
        setLoading(false);
        setMessage(userMessage[1]);
        setAlert(alertClass);
        setStyle({color:"green"});

    }).catch((error)=>{
       // console.log(error);
    });

}

const handleFormData = (event) =>{  
       // console.log(event.target.value);
        setEmail(event.target.value);
}
    return (
        <div className="mic-background-image_reset">
          <NavBar/>
          <div className="format-div-reset"></div>
            
                <h1 className="catalog-title-reset">Reset Password</h1> 

                <div className="product-container-reset">
                {loading ? <div className="spinner-border text-success spinner-color" style={{color:"#c85d4d"}}role="status">
                           <span className="visually-hidden"></span></div>:<p className={"resetText "+alert.alert1+" "+alert.alert2} style={style}>{message}</p>}
                <div className="formDiv-reset"> 
          <div className="form-container">
            <div className="form-group reset-text border">
              <label className="label-text">Email:
                <input className="label-text" type="email"  id="loginEmail" name="Email" placeholder="example@email.com" onChange={handleFormData} />
              </label>
            </div>
            <br />         
          </div>
          </div>
          <br/>
        <div className="buttonDiv"><button className="btn btn-showlove" type="submit" value="Submit" onClick={handleSubmit}>Submit Email</button></div>
       <br/>

                </div>


        </div>
    );
}

export default ResetPassword;