import React from 'react';
import '../css/terms_agreement.css';
import Term_Agreement_Text from './Term_Agreement_Text';
import NavBar from './navBar';

function Terms_Agreement(props) {
    return (
            <div className="mic-background-image-terms">
                <NavBar/>
            <div className="format-div-terms"></div>
                <h1 className="catalog-title-terms">Terms And Agreement</h1>
                <Term_Agreement_Text/>
            </div>
    );
}

export default Terms_Agreement;