import React, {useEffect} from 'react';
import NavBar from './navBar';
import PrivacyText from './PrivacyText';
import '../css/privacyPolicy.css';

function PrivacyPolicy(props) {
        
    return (  
        <div>
            <div className="mic-background-image-privacy">
            <NavBar/>
          <div>
          <div className="format-div-privacy"></div>
          </div>
                <h1 className="catalog-title-privacy">Privacy Policy</h1>
                <div className="product-container-privacy text-style-privacy">
            <div className="web-text-privacy">
                <PrivacyText/>
                </div>
                </div>
            </div>
        </div>
    );
}
export default PrivacyPolicy;