import React from 'react';
import { NavLink } from 'react-router-dom';


function CartIcon2(props) {
    
    return (
        <div className="cart-container-div">
        <NavLink to="/cart" className="Cart"><div>
    <div className="cart-amount" style={{color:"white"}}>{props.cartNumber}</div>
        <div className="shopping-cart"><i className="fas fa-shopping-cart cart-icon"></i></div>
            </div></NavLink>
            <div className="signIn-text" onClick={()=>props.loggOut()}>sign out</div>
        </div>
    );
}

export default CartIcon2;
