import React from 'react';
import '../css/Is_HipHop_Dead.css';

const Wheres_The_Beef = () =>{

    const style = {
        textAlign:"center"
    }

    return(
        <div className="product-container-HipHop">
            <div className="web-text-HipHop">
            <h2 style={style}>Where's The Beef?</h2>
		<h3 style={style}>by Kevin (Truck) Haynes</h3>
        
<p>If you haven’t guessed yet, the title to this article is a rhetorical question because it is obvious that the “Beef” is in Hip Hop.  Is it me?  Or, does anyone else notice the amount of “Beef” in today’s Hip Hop industry.  Everyone who is anyone is at war with someone else in the hip hop industry.  I mean, hip hop has turned into an all-out war reminiscent of a WWE battle royal.  The way things seem to be going, we will never see artists come together to make a track like “Self-Destruction” which came out in 1988 with the stop the violence movement.  Is this the direction the new age youth or Millennials are going to take Hip Hop?  I don’t understand. </p><br/><p id="space"> You would think that with Hip Hop reaching millions of youths, that artistes would take the time to say something or do something more positive since they have the ears of so many youths.  If my memory serves me correctly, Tupac said that the crowd at his concert was so into what he was saying, that if he had told them to turn around in a circle they would have listen.   I guess that is why he told his audience to not just listen to the beat, but listen to the lyrics and hold each artist accountable to what he or she is saying.  I guess what I am trying to say is just like back in the mid-nineties when we lost focus costing us the lives of two of Hip Hop’s biggest influential stars, we are beginning to go down the same path again as if we did not learn a thing all this time.  I don’t know if I am the only one but, I would like to see the Hip Hop community come together instead of feeding us more beef than what is in any farmer’s herd of cows, Just saying.</p>
</div>
        </div>
    );
}

export default Wheres_The_Beef;