import React,{ useState } from 'react';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import NavBar from './navBar'
import '../css/resetPassword.css';

function Create_Password(props) {

  const search = useLocation().search;
  const validator = new URLSearchParams(search).get('validator');  
  const selector = new URLSearchParams(search).get('selector');

    const responseMessage = [
          "Your password has been updated.",
          "We encountered a problem while trying to update your password.  Please contact the site Administrator.",
          "Your password and confirmation password do not match"
    ];

const [alert, setAlert] = useState({alert1:"", alert2:""});

const [ email, setEmail ] = useState("");
const [ password, setPassword ] = useState("");
const [ passwordConf, setPasswordConf ] = useState("");
const [ loading, setLoading ] = useState(false);
const [ userMessage, setUserMessage ] = useState("");
const [ style, setStyle ] = useState({})

//const url = process.env.REACT_APP_API_PATH+"NewPasswordController.php";

const url = "api/NewPasswordController.php";

const handleSubmit = () =>{
  setLoading(true);
    if(password == passwordConf){
      const formData = {
        email,
        password,
        validator,
        selector
    };

    axios.post(url,formData).then((response)=>{

        setLoading(false);
        if(response.data.message === "validator is good")
        {
            setUserMessage(responseMessage[0]);
            setAlert({alert1:"alert",alert2:"alert-success"});
            setStyle({color:"green"});
        }
        else
        {
          setUserMessage(responseMessage[1]);
          setAlert({alert1:"alert",alert2:"alert-danger"});
          setStyle({color:"red"});
        }
      

    }).catch((error)=>{
       // console.log(error);
    });
  }
  else{
          setLoading(false);
          setUserMessage(responseMessage[2]);
          setAlert({alert1:"alert",alert2:"alert-danger"})
          setStyle({color:"red"});
  }

}

const handleFormData = (event) =>{ 
    if(event.target.name == "Email"){ 
        setEmail(event.target.value);
    }
    if(event.target.name == "Password"){ 
        setPassword(event.target.value);
    }
    if(event.target.name == "PasswordConf"){ 
        setPasswordConf(event.target.value);
    }
}
    return (
        <div className="mic-background-image_reset">
          <NavBar/>
          <div className="format-div-reset"></div>
                <h1 className="catalog-title-reset">Reset Password</h1> 

                <div className="product-container-reset padding">
                  <br/>
                {loading ? <div className="spinner-border text-success spinner-color" style={{color:"#c85d4d"}}role="status">
                           <span className="visually-hidden"></span></div>:<p className={"resetText "+alert.alert1+" "+alert.alert2} style={style}>{userMessage}</p>}
                <div className="formDiv-reset">
          <div className="form-container">
            <div className="form-group reset-text-field">
              <label className="label-text">Email:
                <input className="label-text" type="email" className="form-control" id="email" name="Email" placeholder="example@email.com" onChange={handleFormData} />
              </label>
            </div>
            <div className="form-group reset-text-field">
              <label className="label-text">Password:
                <input className="label-text" type="password" className="form-control" id="password" name="Password" placeholder="example@email.com" onChange={handleFormData} />
              </label>
            </div>
            <div className="form-group reset-text-field">
              <label className="label-text">Password Confirmation:
                <input className="label-text" type="password" className="form-control" id="passwordConf" name="PasswordConf" placeholder="example@email.com" onChange={handleFormData} />
              </label>
            </div>
            <br />         
          </div>
          </div>
          <br/>
        <div className="buttonDiv"><button className="btn btn-showlove" type="submit" value="Submit" onClick={handleSubmit}>Submit Email</button></div>
        

                </div>


        </div>
    );
}

export default Create_Password;