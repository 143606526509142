import React,{ useState, useEffect } from 'react';
import axios from 'axios';
import NavBar from './navBar'
import '../css/resetPassword.css';
import PageForbidden from './PageForBidden';
import { useLoaderData } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthentication, setToken,setAccountNo} from "./cartSlice";

function ChangePassword(props) {

  //const {result} = useLoaderData();
  const setAuth = useDispatch();
  const isAuth = useSelector((state) => state.cart.isAuth);


    

    const responseMessage = [
          "Your password has been updated.",
          "We encountered a problem while trying to update your password.  Please contact the site Administrator.",
          "Your password and confirmation password do not match"
    ];

const [alert, setAlert] = useState({alert1:"", alert2:""});

const [ oldPassword, setOldPassword ] = useState("");
const [ newPassword, setNewPassword ] = useState("");
const [ newPasswordConf, setNewPasswordConf ] = useState("");
const [ loading, setLoading ] = useState(false);
const [ userMessage, setUserMessage ] = useState("");
const [ style, setStyle ] = useState({});

const url = process.env.REACT_APP_API_PATH+"ChangePasswordController.php";
const url2 = "api/ChangePasswordController.php";


const handleSubmit = () =>{
  setLoading(true);
    if(newPassword == newPasswordConf){
        const formData = {
            oldPassword,
            newPassword
        }
    
    axios.post(url2,formData).then((response)=>{
        setLoading(false);
        if(response.data.message === "Password Changed")
        {
            setUserMessage(responseMessage[0]);
            setAlert({alert1:"alert",alert2:"alert-success"});
            setStyle({color:"green"});
            setOldPassword("");
            setNewPassword("");
            setNewPasswordConf("");
        }
        else
        {
          setUserMessage(responseMessage[1]);
          setAlert({alert1:"alert",alert2:"alert-danger"});
          setStyle({color:"red"});
        }
      

    }).catch((error)=>{
        //console.log(error);
    });
  }
  else{
          setLoading(false);
          setUserMessage(responseMessage[2]);
          setAlert({alert1:"alert",alert2:"alert-danger"})
          setStyle({color:"red"});
  }

}

const handleFormData = (event) =>{ 
    if(event.target.name == "oldPassword"){ 
        setOldPassword(event.target.value);
    }
    if(event.target.name == "newPassword"){ 
        setNewPassword(event.target.value);
    }
    if(event.target.name == "newPasswordConf"){ 
        setNewPasswordConf(event.target.value);
    }
}

/*useEffect(()=>{
	const urlJwt = "api/jwt/JwtController.php";

	axios.get(urlJwt).then((response)=>{
		setAuth(setAuthentication(response.data.result));
	});},[])*/

    return (
        
        <div className="mic-background-image_reset">
          <NavBar/>
          <div className="format-div-reset"></div>
          { isAuth ? <div> <h1 className="catalog-title-reset">Change Password</h1> 
                
                <div className="product-container-reset">
                <br/>
                {loading ? <div className="spinner-border text-success spinner-color" style={{color:"#c85d4d"}}role="status">
                           <span className="visually-hidden"></span></div>:<p className={"resetText "+alert.alert1+" "+alert.alert2} style={style}>{userMessage}</p>}
                <div className="formDiv-reset">
          <div className="form-container">
          
            <div className="form-group reset-text-field">
              <label className="label-text">Old Password:
                <input className="label-text form-control" type="text"  id="oldPassword" name="oldPassword" value={oldPassword} placeholder="Enter old password here" onChange={handleFormData} />
              </label>
            </div>
            <div className="form-group reset-text-field">
              <label className="label-text">Password:
                <input className="label-text form-control" type="password" id="newPassword" name="newPassword" value={newPassword} placeholder="Enter new password here" onChange={handleFormData} />
              </label>
            </div>
            <div className="form-group reset-text-field">
              <label className="label-text">Password Confirmation:
                <input className="label-text form-control" type="password" id="newPasswordConf" name="newPasswordConf" value={newPasswordConf} placeholder="Enter new password again" onChange={handleFormData} />
              </label>
            </div>
            <br />         
          </div>
          </div>
          <br/>
        <div className="buttonDiv"><button className="btn btn-showlove" type="submit" value="Submit" onClick={handleSubmit}>Submit Email</button></div>
        <br/>

                </div>
               
</div>: <PageForbidden/>}

        </div>
    );
}


  
  
  export default ChangePassword;
