import React, {useEffect} from 'react';
import NavBar from './navBar';
import '../css/freeSoundPatch.css';

function FreeSoundPatch(props) {

    const freeFormat = {
        color:"black",
        textAlign:"center"
    }
        
    return (  
        <div className="mic-background-image-freeSoundPatch">
        <NavBar/>
      
      <div className="format-div-freeSoundPatch"></div>
      
            <h1 className="catalog-title-freeSoundPatch">Free Sound Patches</h1>
            <div className="product-container-freeSoundPatch text-style-freeSoundPatch">
        <div className="web-text-freeSoundPatch">
            <div className="soundpatch-div"><div className="soundpatch-image-text">Kontakt Gideon Sound Patch vol. 1</div><div className="soundpatch-image-div"><a href="freeSynths/Kontakt_Synths.zip"><img className="soundpatch-image" src="Synth_Images/kontakt.jpg" alt="kontakt download"/></a></div></div>
            <div className="soundpatch-div"><div className="soundpatch-image-text">Wav Gideon Sound Patch vol. 1</div><div className="soundpatch-image-div"><a href="freeSynths/Wav_Synths.zip"><img className="soundpatch-image" src="Synth_Images/wav_file_logo.jpg" alt="wav file download"/></a></div></div>
            </div>
            </div>
        </div>
    );
}
export default FreeSoundPatch;