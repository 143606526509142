import React from 'react';
import NavBar from './navBar';
import AboutUsContent from './About_Us_Content';
import '../css/about_us.css';

function AboutUs() {
        
    return (  
        <div>
            <div className="mic-background-image-about">
            <NavBar/>
          <div>
          <div className="format-div-about"></div>
          </div>
                <h1 className="catalog-title-about">About Us</h1>
                <AboutUsContent/>
            </div>
        </div>
    );
}
export default AboutUs;