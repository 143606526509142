import React from 'react';
import MainBackgroundSection from './mainBackgroundSection.js';
import Playlist from './playlist';
import '../css/animateB.css';
import FadeTest3 from './FadeTest3';
import '../css/mainBackgroundSection.css';
import Pricing from './Pricing';
import NavBar from './navBar';





function Home(props){

        return (
            <div>
                <NavBar/>
                <FadeTest3/>
                <Playlist/>
                <Pricing/>
            </div>
        );
}

export default Home;