import React, {useEffect} from 'react';
import NavBar from './navBar';
import '../css/freeBeats.css';

function FreeBeats(props) {

    const freeFormat = {
        color:"black",
        textAlign:"center"
    }
        
    return (  
        <div className="mic-background-image-freeBeats">
            <NavBar/>
          
          <div className="format-div-freeBeats"></div>
          
                <h1 className="catalog-title-freeBeats">Free Loop Sets</h1>
                <div className="product-container-freeBeats text-style-freeBeats">
            <div className="web-text-freeBeats">
                <h3 style={freeFormat}>FREE BEATS COMMING SOON!</h3>
                </div>
                </div>
            </div>
    
    );
}
export default FreeBeats;