// @flow
import React from 'react';
import {Link} from 'react-router-dom';
//import $ from 'jquery';
//import {useSpring, animated} from 'react-spring'


export default function MainBackgroundSection(){
            
    return (     
          <div id="1"  className="dropdown-content">
<div className="dropdown-text-div"><Link to="/about_us"><p>ABOUT US</p></Link></div>
<div className="dropdown-text-div"><Link to="/contact_us"><p>COUNTACT US</p></Link></div>
<div className="dropdown-text-div"><Link to="/free_sounds"><p>FREE SOUNDS</p></Link></div>
<div className="dropdown-text-div"><Link to="/customer_account"><p>USER ACCOUNT</p></Link></div>
<div className="dropdown-text-div"><Link to="/catalog"><p>BEATS &amp; LOOPS</p></Link></div>
</div> 
    );
  }
