import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    products:[],
    cartItems:[],
    token:"",
    isAuth:false,
    data:"",
    rx89tuv:"",
    isShowing:true
};

export const cartSlice = createSlice({

   name:'cartItems',
   initialState,
reducers: {
    addToCart:(state = initialState, action)=>{
        //console.log(state.cartItems);
        state.cartItems.push(action.payload); 
      // return {...state, cartItems: action.payload}
    },
    removeFromCart:(state, action)=>{
        let newItemsArray = state.cartItems.filter((item) => {
            return action.payload != item.id
        })
        return{...state, cartItems: newItemsArray}
    },
    setEmptyCart:(state,action)=>{
        return {...state, cartItems:action.payload}
    },
    setAuthentication:(state,action)=>{
        return {...state, isAuth:action.payload}
    },
    setToken:(state, action)=>{
        return {...state, token:action.payload}
    },
    setData:(state, action)=>{
        return {...state, data:action.payload}
    },

    setRX89TUV:(state, action)=>{
        return{...state, rx89tuv:action.payload}
    },

    setModalState:(state,action)=>{
        console.log(action.payload);
        return{...state, isShowing:action.payload}
    }
}
});

export const {addToCart, removeFromCart, setEmptyCart, setAuthentication, setToken, setRX89TUV, setData, setModalState} = cartSlice.actions;

export default  cartSlice.reducer;