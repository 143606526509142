import React from 'react';
import '../css/about_us.css';


function Faq_Content(props) {


    return (
        <div className="product-container-faq text-style-faq">
            <div className="web-text-faq">
            <p><b>1. Can I use a Show Love Production beat however I want?</b><br/>
<b>No.</b>  Show love productions beat cannot be used to glorify drugs, alcohol, sex, or violence.  This is not to say that you cannot talk about what happens in your hood but, unruly behavior must not be glorified.  You must articulate what you say in a respectful manner.</p>
		<br/>
		<p><b>2. When I buy a license to a beat or loop set, do I own it?</b><br/>
		<b>No. </b>Show Love productions does not sell beats to customers but, we only sell the right to use our beats in a customer’s work or compositions.</p>
		<br/>
		<p><b>3.  Does Show Love Productions sell exclusive beat licenses?</b></p>.<br/>
		<p><b>Generally No. </b> Show Love Productions Generally does not sell exclusive beats at this time.  However, producers and artist who have songs that will promote a positive attitude in the community can send a copy of their song’s lyrics and an agreement can be worked out.</p>
		<br/>
		<p><b>4. Does Show Love Productions sell the beat’s ownership to customers?</b></p><br/>
		<p><b>No. </b>  Show Love Productions only sell a license to use the beat.  We do not transfer ownership of our beats or construction loop sets to our customers.</p>
		<br/>
		<p><b>5.  What formats do I get my beat in?</b><br/>
		Show Love Production’s beats are giving in WAV format.</p>
		<br/>
		<p><b>6. Do I have to register to purchase a license for a beat or loop set?</b><br/>
		<b>Yes.</b>  All customers must register with our company to purchase a license.  The only information we require to register is your first and last name and your email address.  However, if necessary, more information can be obtained from the archived information produced from the electronic payment transaction.</p>
		<br/>
		<p><b>7. What is the difference between a construction loop set and a beat?</b><br/>
		Construction Loop designed to have other musical elements added to it or, to have elements of the loop set extracted and added to a producer or artists’ composition or song.  Beats on the other hand or designed to be used as is.  Also, musical elements can be extracted and used in other compositions or songs by the owner of the license.  Musical elements can also be added to the purchased beat by the license owner if he or she chooses.</p>
		<br/>
		<p><b>8. If I use constructions loop or beats individual track or sample am I still bound by the terms and agreement?</b><br/>
		<b>Yes.</b>  The terms and agreement applies to any song or composition that contains any beat, sample, riff, musical phrase, or Musical element owned by Show Love Productions.</p>
		<br/>
		<p><b>9. What do I get when I purchase a construction loop set license?</b><br/>
		Loop sets generally consist of 16 to 24 bars and at least eight tracks of music samples.  Also, a pair of two channel stereo tracks are also provided that are mastered and un-mastered.  An mp3 stereo tracks may or may not be provided.</p>
           </div>
        </div>
    );
}

export default Faq_Content;