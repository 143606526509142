import React, {useState} from 'react';
import Tracks from './Tracks';
import '../css/tracks.css';

const AudioPlayer2 = () =>{
    
    const [songTitle, setSongTitle] = useState("");
    /*const [trackIndex, setTrackIndex] = useState(-1);*/
    

    const tracks = [
        {id: 0,  songName:["All God's People", "All_God_s_People"]},
        {id: 1,  songName:["God's Grace", "God_s_Grace"]},
        {id: 2,  songName:["Show Love Riddim", "ShowLove_Riddim"]},
        {id: 3,  songName:["Stand Up For Love (Remix)", "Stand_Up_For_Love"]},
        {id: 4,  songName:["Heart Beat", "HeartBeat"]},
        {id: 5,  songName:["God Gives Good Things", "God_Gives_Good_Things"]},
        {id: 6,  songName:["Positive Muzik", "Positive_Muzik"]}
     ];


    return(
        <div className="player-container">
            
            <div className="player">
            <div className="picture-container">
                <img className="song-picture"  src="Pictures/ShowLove_Productions_image.jpg"/>
            </div>
            <div classname="track-container">
                <hr/>
                {tracks.map((track)=>       
                <Tracks
                    key={track.id}
                    songName={track.songName[0]}
                    songUrl={track.songName[1]}
                    id={track.id}
                />)}
                    
            </div>
            </div>
        </div>
    );

}
export default AudioPlayer2;