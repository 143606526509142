import React, {useState, useEffect} from 'react';
import Modal from './Modal';
//import {useHistory} from 'react-router-dom';
//import CartIcon2 from './CartIcon';
//import '../css/Modal.css'
//import cart from './cart';

const CartIcon1 = (props) => {
  const [show, setShow] = useState(false);


  const showModal = () => {
     setShow(true);
   };
 
  const hideModal = () => {
     setShow(false);
   };
  

    return (
        <div>  
             <Modal getIsAuth={props.getIsAuth} show={show} handleClose={hideModal}/>   
    <div  className="cart-container-div" onClick={()=>showModal()}> 
    <div className="cart-amount">{props.cartNumber}</div>
    <div className="shopping-cart"  ><i className="fas fa-shopping-cart cart-icon"></i></div>
    <div className="signIn-text">Sign In/Register</div>
    </div> 
        </div>
    );
}

export default CartIcon1;