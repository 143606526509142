import Products from './Products2';
import NavBar from './navBar';
import SearchBar from './SearchBar';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { useLoaderData } from 'react-router-dom';
import { addToCart } from './cartSlice.js';
import '../css/catalog.css';



function Catalog(props) {

	const {products} = useLoaderData();
	console.log(products.data.rows);
   const [product, setProducts] = useState(products.data.rows);  
   const [count, setCount] = useState(products.data.numberOfPages);
 //  const [page, setPage] = useState([]);
  // const [pageIndex, setPageIndex] = useState(0);
   const [order, setOrder] = useState(0);
   const [offset,setOffSet] = useState(0);
   //const [currentPage, setCurrentPage] = useState(0);
  // const [isSelected, setIsSelected] = useState(false);
  const cartItems = useSelector((state)=>state.cart.cartItems);
  const isAuth = useSelector((state)=>state.cart.isAuth);
  const addItem = useDispatch();



   
   var widthCalc = (count*43);
   let cart = cartItems;
 
function addToBasket(product_id,item_name,item_price,item_image,file_name,zip_file)
{
	let cartObject = {};
	if(!isAuth){
		alert("You must be registered and logged in to make a purchase.")
	}
	else{
			var message = false;
			
			if(cartItems.length == 0)
			{		
				cartObject =
					{
						id:product_id,
						name:item_name,
						price:item_price,
						itemImage: item_image,
						fileName: file_name,
						zipFile: zip_file
					};

				addItem(addToCart(cartObject));

				alert(item_name + " has been added to your cart.");
			}
			else
			{
				for(let i = 0; i < cartItems.length; i++)
				{
					if(product_id == cartItems[i].id)
					{
						message = true;
			}
				}
				if(message)
				{
					alert(item_name + " is already in your cart.");
				}
				else
				{
			
					/* cart.push(
						 {
							id:product_id,
							name:item_name,
							price:item_price,
							itemImage: item_image,
							fileName: file_name
						});*/

						cartObject = {
							id:product_id,
							name:item_name,
							price:item_price,
							itemImage: item_image,
							fileName: file_name
						};
					addItem(addToCart(cartObject));
					if(!isAuth){
						alert("You must be registered and logged in to make a purchase.")
					}
					else{
						alert(item_name + " has been added to your cart.");
					}
				}
			}
		}
}

   useEffect(()=>{

		const sortInfo = {
			order,
			offset,
		}
  
		//var url = process.env.REACT_APP_API_PATH+"CatalogController.php";
		const url_development = 'api/CatalogController.php';
		const url_production = 'api/CatalogController.php';


      axios.post(url_production,sortInfo)
    .then(response=>{
       setProducts(response.data.rows);

	
       setCount(response.data.numberOfPages);
	  if(offset > count){
		setOffSet(0);
	  }
	

    }).catch(error=>{
        //console.log(error);
    });
	},[order,offset,count]);
	
	const bkColor = 'backgroundColor:"white"';
		const foregroundColor = 'color:"#c85d4d"';

	const getOrderBy = (number) =>{
		setOrder(number);
	}

	const linkNumber = product.length - 1;

	
	var item = [];
	for(var i = 0; i < count; i++){
		if(i == offset){
			item.push({num:i,isSelected:true});
		}
		else{
			item.push({num:i,isSelected:false});
		}
	}

	const handlePagination = (number) =>{
			setOffSet(number);
			//setCurrentPage(number);
	}

	
	
	

    return (
		<div>
        <div className="mic-background-image-catalog">
            <NavBar cart={cart}/>
            <div className="format-div-catalog" ></div>
            <h1 className="catalog-title-catalog">Loops &amp; Beats</h1>
			<SearchBar getOrderBy={getOrderBy}/>
            <div className="product-container-catalog">
                <div className="product-outer-contanier">
                
                 {product.map((productA,index)=> index == linkNumber ? "" : <Products 
					key = {productA.Item_ID}
					id = {productA.Item_ID}
                    genre = {productA.Genre}
                    itemName = {productA.Item_Name}
                    productType= {productA.Product_Type}
                    fileName = {productA.File_Name}
                    itemPrice = {productA.Item_Price}
                    demoName = {productA.Demo_Name}
					productImage = {productA.Product_Image}
					zipFile = {productA.Zip_File}
					shoppingCart = {addToBasket}
                    />)}
                </div> 
                <div id="pageination-container" className="pagination-div-container" style={{width:widthCalc + 'px'}}>
				 {item.map((pageNum)=>{return(<div id={"pageDiv"+pageNum.num} className={pageNum.isSelected ? "selected" : "pageDiv"} onClick={()=>handlePagination(pageNum.num)}>{pageNum.num+1}</div>)})}
			 </div> 
        </div>
		</div>
		</div>
    );
}

export default Catalog;
