import React from 'react'
import NavBar from './navBar';
import '../css/Is_HipHop_Dead.css';
import Is_HipHop_Dead_Content from './Is_HipHop_Dead_Content';


const Is_HipHop_Dead = (props)=> {
    

    return (  
        <div>
            <div className="mic-background-image-HipHop">
            <NavBar/>
          <div>
          <div className="format-div-HipHop"></div>
          </div>
                <h1 className="catalog-title-HipHop">Show Love Blog</h1>
                <Is_HipHop_Dead_Content/>
            </div>
        </div>
    );
}

export default Is_HipHop_Dead
