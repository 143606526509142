import React, {useState,useEffect} from 'react';
import axios from 'axios';
import NavBar from './navBar';
import PageForBidden from './PageForBidden';
import { useSelector, useDispatch} from 'react-redux';
import { setAuthentication, setToken,setAccountNo} from "./cartSlice";
import { Link, useLoaderData } from 'react-router-dom';
import '../css/personalInfo.css';



const ViewPersonalInfo = (props)=>{


    const setAuth = useDispatch();
    const isAuth = useSelector((state) => state.cart.isAuth);

const [firstName, setFirstName] = useState("");
const [lastName, setLastName] = useState("");
const [email, setEmail] = useState("");
const [userName, setUserName] = useState("");
const [companyName, setCompanyName] = useState("");
const [genre, setGenre,] = useState("");




const urlEdit = "api/EditInfoController.php";

const mainBack = {
    height:"100vh"
}

const button = {
    width:"200px",
    height:"50px",
    borderRadius:"20px",
    color:"white",
    backgroundColor:"#c85d4d",
    margin:"auto",
    paddingTop:"10px",
    textAlign:"center",
    textDecoration:"none"
}

useEffect(()=>{

    axios.get(urlEdit).then((response)=>
    {
        
        if(response.data.result){
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setUserName(response.data.userName);
        setCompanyName(response.data.companyName);
        setGenre(response.data.genre);
        setEmail(response.data.email);  
        }
        else{
            setAuth(setAuthentication(false));
        }
                  
})},[]);
        

    return (
        <div style={mainBack} className="mic-background-image-personalInfo">
        <NavBar/>
    <div>
        <div className="format-div-personalInfo"></div>
        <h1 className="catalog-title-personalInfo">View Personal Information</h1>
        {!isAuth ? <div className="purchased-items-message">You must be logged in to view this page.</div>: 
        <div className="product-container-personalInfo">
           
            <div className="web-text">
                 
            <div>
            
             
             <div className="formDiv-personalInfo message-format">
                 <div className="view-info-innerDiv">
             <div><span className="view-title"><b>First Name:</b></span><span>{firstName }</span></div>
             <div><span className="view-title"><b>Last Name:</b></span><span>{lastName }</span></div>
             <div><span className="view-title"><b>Email:</b></span><span>{email }</span></div>
            <div><span className="view-title"><b>UserName:</b></span><span>{userName }</span></div>
            <div><span className="view-title"><b>Company Name:</b></span><span>{companyName }</span></div>
            <div><span className="view-title"><b>Genre:</b></span><span>{genre}</span></div><br/>
        </div>
        </div>
        <br/>
        <Link to="/edit_personal_info"><div className="view-info-button" style={button}>Edit Personal Information</div></Link>
       
        </div>
        
     </div>
     </div>}
     </div>
     </div>

       
    );
}

export default ViewPersonalInfo;
