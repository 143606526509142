import React from 'react';
import '../css/StyleSheet/Show_LoveStyle.css';
import '../css/Slider3.css';
import '../css/test2.css';

function Pricing(props) {
    return (
        <section id="price" className="formatDiv mic_image" style={{backgroundColor:"#0d0d0d"}}>
          <div id="pricing_div">
<div className="row formatDiv priceSectionPadding">
  <div className="col-md-12">
  <h2 className="pricing-header">Beat Pricing</h2>
</div>
</div>
<div className="row">
<div className="col-md-4 plan">
  
  <div className="plan_title titleCorrect2">
    <h3>Construction Loop Set</h3>
    <p id="con_pricing">(Multi-Track always included)</p>
  </div>
  <div className="plan_sub_title">
    <h2 className="dollar pricing-header">$5</h2>
   

  <div className="plan_list bottomPadding">
    <ul>
      <li>Untagged mp3 &amp; wav files</li>
      <li>Unlimited Distribution</li>
      <li>All Key Signatures and Beats per minutes Provided</li>
      <li>Royalty Free</li>
      <li>Ownership Remains With Show Love Productions</li>
      <li>Must Give Show Love Productions credit<br/>for beat used in composition</li>
      <li>Instant Deliver to <br/> Registered Email Address</li>
      <li>Access to Purchased Songs<br/>At Any Time</li>
      <li>Must Register</li>
      <li>Registration is Free</li>
      <li><b>Must Agree to Terms &amp; Agreement</b></li>
    </ul>
  <div className="centerButton"><a href="Terms_Agreement" className="btn btn-warning">Terms &amp; Agreement</a></div>
</div>
</div>
  </div>
  <div className="col-md-4 plan">
    <div className="plan_title con_loop">
      <h3>Instrumental</h3>
      <p>(No Multi-Track)</p>
    </div>
    <div className="plan_sub_title">
      <h2 className="dollar pricing-header">$15</h2>
 
    <div className="plan_list bottomPadding">
    <ul>
      <li>Untagged mp3 &amp; wav files</li>
      <li>Unlimited Distribution</li>
      <li>All Key Signatures and Beats per minutes Provided</li>
      <li>Royalty Free</li>
      <li>Ownership Remains With Show Love Productions</li>
      <li>Must Give Show Love Productions credit<br/>for beat used in composition</li>
      <li>Instant Deliver to <br/> Registered Email Address</li>
      <li>Access to Purchased Songs<br/>At Any Time</li>
      <li>Must Register</li>
      <li>Registration is Free</li>
      <li><b>Must Agree to Terms &amp; Agreement</b></li>
    </ul>
    <div className="centerButton"><a href="Terms_Agreement" className="btn btn-warning">Terms &amp; Agreement</a></div>
  </div>
  </div>
    </div>
   
    <div className="col-md-4 plan margin-correct">
    
      <div className="plan_title con_loop titleCorrect">
        <div id="bestValue">
          <p>BEST VALUE</p>
        </div>
        <h3>Instrumental</h3>
        <p>(With Multi-Track)</p>
      </div>
      <div className="plan_sub_title">
        <h2 className="dollar pricing-header">$30</h2>

       <div className="plan_list bottomPadding">
      <ul>
        <li>Untagged mp3 &amp; wav files</li>
        <li>Unlimited Distribution</li>
        <li>All Key Signatures and Beats per minutes Provided</li>
        <li>Royalty Free</li>
        <li>Ownership Remains With Show Love Productions</li>
        <li>Must Give Show Love Productions credit<br/>for beat used in composition</li>
        <li>Instant Deliver to <br/> Registered Email Address</li>
        <li>Access to Purchased Songs<br/>At Any Time</li>
        <li>Must Register</li>
        <li>Registration is Free</li>
        <li><b>Must Agree to Terms &amp; Agreement</b></li>
      </ul>
      <div className="centerButton"><a href="Terms_Agreement" className="btn btn-warning">Terms &amp; Agreement</a></div>
    </div>
  </div>
</div>
</div>
</div>
</section>
    );
}

export default Pricing;