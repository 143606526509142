import AudioPlayer from './AudioPlayer2';
import '../css/playlist.css';

const Playlist = () =>{
   
        return (
            <div id="playlist" className="mic_image">
                <AudioPlayer/>
            </div>
        );
    
}

export default Playlist;