import React, {useState, useEffect} from 'react';
import {Link /*withRouter*/} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import '../css/cart.css';
//import { remove } from 'lodash';
//import { remove } from './actions/addActions';
import { removeFromCart } from './cartSlice';
import PayPalV2 from './PayPal_v2_0';
import axios from 'axios';


function Cart(props) {

const [cuponCode, setCuponCode] = useState("");
const [discount, setDiscount] = useState("0.0");//data coming from db must be converted to fla
const [discountSwitch, setDiscountSwitch] = useState(false);
const remove = useDispatch();
const cartItem = useSelector((state)=>state.cart.cartItems);
const accountNo = useSelector((state)=>state.cart.accountNo);


console.log(cartItem);

var number =0.0;
var discountNumber = 0.0;
var messageSwitch = false;
var tempNum = 0.0;
var tax = 0.0;
const PST = 0.13;
var grandTotal = 0.0;
const url = "api/CuponCodeController.php";
var discountStyle = "";
//const [taxVar, setTaxVar] = useState(0.0);

if(cartItem.length == 0){
    messageSwitch = true;
}

function getTax(subTotal){

    /*if(discountSwitch){
        subTotal = subTotal - (subTotal * discount);
    }*/
    var tax = subTotal * PST;
   //setTaxVar(tax);
    return tax;
}

const formatDiscount = (string)=>{
    if(typeof(string) === "undefined"){
        string = "0.0";
    }
  var discountAmount = string.split(".");

    return discountAmount[1];

}

function getGrandTotal(subTotal){
    var total = subTotal + getTax(subTotal);
    var disFloat = parseFloat(discount);
    if(discountSwitch){
        total = total - (total * disFloat);
    }
    return total;
}


function removeItem(item_id){
    remove(removeFromCart(item_id));

}

const handleOnSubmit=()=>{
    const userCode = {
        cuponCode
    };

    axios.post(url,userCode).then(
        (response)=>{
            if(typeof(response.data.discountAmount) === "undefined"){
                setDiscountSwitch(false);
            }
            else{
                setDiscount(response.data.discountAmount);
                setDiscountSwitch(true);
            }
        }
    ).catch(
        (error)=>{
            //console.log(error);
    });

}

const handleCuponCode = (event) =>{
    setCuponCode(event.target.value);
}

if(discountSwitch){
    discountStyle = "discount-format";    
}
//***loop through products array here***
/*if(props.location.data.length == 0)
{
    message = "Your Cart is Empty."
    messageSwitch = true
}
else
{
    setProducts(props.location.data);
    messageSwitch = false;
}*/

//console.log(props.products);





    return (
 
        <div>   
            <div className="mic-background-image-cart" style={messageSwitch ? {height:"100vh"}: {height:"fit-content"}}>
            <div className="format-div-cart"></div>
            <h1 className="cart-title-text">Shopping Cart</h1>
            <div className="product-container-cart">
                {
                messageSwitch ? <p className="cart-text">Your Cart is Empty.</p> :
                cartItem.map(item=><div className="cart-button-container"><div className="cart-button" onClick={()=>removeItem(item.id)}>remove</div><div className="cart-image-div"><img key={item.id} src={`Product_thumbNails/thumb_${item.itemImage}`}/><span className="center-text">{item.name}</span></div><div className="cart-price-div"><span>${item.price}</span></div></div>)
                 }
                 {cartItem.map(item=> {
                     tempNum = tempNum + parseFloat(item.price)
                 })}
                 <br/>
                {messageSwitch ? <div className="back-to-shop"><Link to="/catalog">Keep Shopping</Link></div> : ""}
                 {
                messageSwitch ? "" :
                 <div className="cart-total-container">
                 <div className="cart-total-text">SUBTOTAL:</div><div className="cart-total-number">${tempNum.toFixed(2)}</div>
                 <div className="cart-total-text">TAX:</div><div className="cart-total-number">${getTax(tempNum).toFixed(2)}</div>
                 <div className={"cart-total-text "+discountStyle} style={discountSwitch ? {display:"inline-block"} : {display:"none"}}>{discountSwitch ? "DISCOUNT - %"+formatDiscount(discount):""}</div><div className={"cart-total-number "+discountStyle} style={discountSwitch ? {display:"inline-block"} : {display:"none"}}>{discountSwitch ? "-$"+((tempNum + getTax(tempNum))* parseFloat(discount)).toFixed(2) : "" }</div> 
                 <div className="cart-total-text borderTop">GRANDTOTAL:</div><div className="cart-total-number borderTop">${getGrandTotal(tempNum).toFixed(2)}</div>
                </div>
                 }
                 <br/>
                 {messageSwitch ? "" : <div className="cupon-code-text">Cupon Code</div>}
                {messageSwitch ? "" : <div className="cupon-code"><input type="text" className="form-control" value={cuponCode} onChange={handleCuponCode}/></div>}
                {messageSwitch ? "" : <div className="cupon-code-button" onClick={handleOnSubmit}>submit cupon code</div>}
                 {messageSwitch ? "" :
               <PayPalV2 
                    cartDetails={cartItem} 
                    discountSwitch={discountSwitch} 
                    discount={parseFloat(discount)} 
                    accountNo={accountNo} 
                    tax={getTax(tempNum).toFixed(2)} 
                    total={getGrandTotal(tempNum).toFixed(2)}
                    discountAmount={((tempNum + getTax(tempNum))* parseFloat(discount)).toFixed(2)}
                    />
                }
                <br/>
                {messageSwitch ? "" :  <div className="back-to-shop"><Link to="/catalog">Keep Shopping</Link></div>}
                </div>
            </div>
        </div>

    );
}



//export default connect(mapStateToProps,mapDispatchToProps)(Cart);
export default Cart;