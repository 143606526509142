import React from 'react';
import '../css/Registration.css';
import NewCustomerForm from './NewCustomerForm';
//import NavBar from ''

function Registration(props) {
    return (
        <div>
            <div className="mic-background-image-registration">
            <div className="format-div-registration"></div>
            
                <h1 className="catalog-title-registration">Registration</h1>
                <NewCustomerForm/>
            </div>
            
        </div>
    );
}

export default Registration;