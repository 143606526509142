import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {Link,NavLink} from 'react-router-dom';

import { useSelector, useDispatch} from 'react-redux';
import { setModalState} from "./cartSlice";
import "../css/Cookie_Modal.css";


const Cookie_Modal = (props) =>{

    
    const [display, setDisplay] = useState({});
    //const setModal = useDispatch();

   /* const closeModal = () =>{
        setModal(setModalState(false));  
    }*/



    const animateModal = props.show ? "inModalRight CookieModal" : "hideModal";


    return(
        <div className={animateModal}>
            <div className="close-button" style={{float:"right",display:"block"}}><i class="fas fa-times-circle close-icon modal-close" onClick={() => props.hideModal()}></i></div>
            <br/>
            <div style={{color:"black",paddingLeft:"5px"}}>
                Our website uses cookies to enhance our customer's browsing experince.<br/>
                By using our website, you agree to the use of cookies as described in our {<NavLink to="/privacy_policy" style={{textDecoration:"none"}}>privacy policy.</NavLink>}
                </div>
        </div>
    )

}

export default Cookie_Modal;
