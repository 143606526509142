import React from 'react';
import NavBar from './navBar';
import {Link} from 'react-router-dom';
import '../css/customerAccount.css'
const CustomerAccountContent = (props)=>{

    

    return(
        <div className="mic-background-image-customerAccount">
             <NavBar/>
            <div className="format-div-customerAccount"></div>
            <div className="catalog-title-customerAccount"><h1>User Account</h1></div>

        <div className="product-container-customerAccount about-us-style">
            <div className="account-div">
            <Link to="/view_personal_info" className="nav-bar-links" style={{textDecoration:"none"}}><div className="account-text">View Personal Infomation</div></Link>
            <Link to="/change_password" classname="nav-bar-links" style={{textDecoration:"none"}}><div className="account-text">Change Password</div></Link>
            <Link to="/purchased_products" className="nav-bar-links" style={{textDecoration:"none"}}><div className="account-text">Purchased Products</div></Link>
            </div>
        </div>
        </div>

    );
}
export default CustomerAccountContent;