import React, { useState, useEffect } from 'react';
import { useTransition, animated } from '@react-spring/web';
import {Link} from 'react-router-dom';


import image1 from '../photos/photo1.jpg';
import image2 from '../photos/photo2.jpg';
import image3 from '../photos/photo3.jpg';
import image4 from '../photos/photo4.jpg';
import '../css/test2.css';
import '../css/mainBackgroundSection.css';
import '../css/Slider3.css';


const slides = [
  { id: 0, url: image1, text: 'Show Love Productions'},
  { id: 1, url: image2, text: 'Quality Beats & Loops'},
  { id: 2, url: image3, text:'Professional Sound'},
  { id: 3, url: image4, text:'Promote Your Sound'}
];

export default function FadeTest3() {
  const [index, set] = useState(0)
  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, delay:16000},
    config: { duration: 3000 },
    onRest: (_a, _b, item) => {
      if (index === item) {
        set(state => (state + 1) % slides.length)
      }
    },
    exitBeforeEnter: true,
  })
  return (
    <div className="flex fill center">
      {transitions((style, i) => (
        <animated.div
          className="init-bg2"
          style={{
            ...style,
            backgroundImage: `url(${slides[i].url})`,
          }}
        >
        <div className="animate__animated animate__fadeInLeft h1-div-grey">
        <h1>{slides[i].text}</h1>
      </div>
      <div className="h1-button-container animate__animated animate__bounceInUp"><Link to="/about_us" style={{textDecoration:"none",color:"#c85d4d"}}><div className="h1-button">Learn More</div></Link><Link to="/contact_us" style={{textDecoration:"none",color:"#c85d4d"}}><div className="h1-button hideButton">Contact Us</div></Link></div>
      {/*<div className="h1-button-container2 animate__animated animate__bounceInUp"><Link to="/about_us" style={{textDecoration:"none",color:"#c85d4d"}}><div className="h1-button2">Learn More</div></Link><br/><br/><Link to="/contact_us" style={{textDecoration:"none",color:"#c85d4d"}}><div className="h1-button2 hideButton">Contact Us</div></Link></div>*/}
        </animated.div>
      ))}
    </div>
  )
}
