import React from 'react';
import PrivacyText from './PrivacyText';
import PDF from "../documents/privacy.pdf";


const PrivacyModal = (props) => {
  var showHideClassName = props.show2 ? "modal display-block" : "modal display-none";

    return (
       <div className={showHideClassName}>
      <div className="modal-main modal-format">
        <div className="close-button"><i class="fas fa-times-circle close-icon modal-close" onClick={()=>props.hideModal2()}></i></div>
        <div className="modal-header-div"><h2 className="modal-header"></h2></div>
        <PrivacyText/>
        <div><a href={PDF}>CLICK HERE FOR PDF VERSION OF PRIVACY POLICY</a></div>
    </div>
    </div>
    );
};

export default PrivacyModal;