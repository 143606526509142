import React from 'react';
import NavBar from './navBar';
import '../css/pageForbidden.css'

const PageForbidden = ()=>{
    return(
        <div>
        <div id="main-background-section-forbidden">
        <NavBar/>
        <div className="format-div-forbidden"></div>

        <div className="h1-div-grey-forbidden">
            <h1 className="catalog-title-forbidden">You Must be Registered and Logged in to Access this Page.</h1>
            </div>
        </div>
    </div>
    );
}

export default PageForbidden;