import React, {useEffect} from 'react';
import NavBar from './navBar';
import axios from 'axios';
import { connect } from 'react-redux';
import { setAuth } from './actions/addActions';
import { setToken } from './actions/addActions';
import { addToBasket } from './actions/addActions';
import OrderConfContent from './OderConfContent';
import { useDispatch } from 'react-redux';
import { setEmptyCart } from './cartSlice';
import '../css/orderConfirmatin.css';
import '../css/spinner.css';

function OrderConfirmation(props) {

const url_d = "api/LogoutController.php";
const url_p = "api/LogoutController.php";

const emptyCart = useDispatch();



const loggedOut = ()=>{
    const data = {
        action:"logout"
    };
    axios.post(url_d,data).then((res)=>{
    emptyCart(setEmptyCart());
    
    //setLoggedIn(false);
    }).catch((err)=>{/*console.log(err)*/});
}

/*useEffect(()=>{
    loggedOut();
},[])*/

    return (
        <div>
            <div className="mic-background-image-confirmation" style={{height:"100vh"}}>
            <NavBar/>
            <div className="format-div-confirmation"></div>
            <br/>
            <br/>
                <OrderConfContent/>
            </div>
        </div>
    );
}

export default OrderConfirmation;