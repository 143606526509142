
import React,{useState, useEffect} from "react";
import {Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch} from 'react-redux';
import { setAuthentication, setToken,setAccountNo,setRX89TUV,setData} from "./cartSlice";

function Modal(props) {
  var showHideClassName = props.show ? "modal display-block" : "modal display-none";
  const setAuth = useDispatch();
  const setTokenA = useDispatch();
  const setRX89TUV_v = useDispatch();
  const cartItems = useSelector((state)=>state.cart.cartItems);
  const setDataA = useDispatch();
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [message, setMessage] = useState(false);
  const [stayLoggedInValue, setStayLoggedInValue] = useState(false);
  //const [cookie, setCookie] = useCookies(['RCF4589']);
  const [login, setLogin] = useState({
    loginResult: false,
    progress: -1,
    displayMessage: -1
  });


  var alertMessage = "";
  var alertClassText = "";

  const loginMessage = [
    "Your have successfully logged in.",
    "Invalid user name or password."
  ];

  const alertClass = [
    "alert-success",
    "alert-danger"
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    if (emailValue == "") {
      setMessage(true);
      return;
    }

    else {
      setMessage(false);
    }


    const loginData = {
      email:emailValue,
      password:passwordValue,
      stayLoggedIn:stayLoggedInValue
    };


    axios.post("api/CustomerController.php", loginData).then((response) => {
      console.log(response);
      setLogin({
        loginResult: response.data.result,
        displayMessage: response.data.displayMessage,
      });
      setDataA(setData(response.data.data));
      setTokenA(setToken(response.data.token));
      setRX89TUV_v(setRX89TUV(response.data.RX89TUV));
      //setAccountNoA(setAccountNo(response.data.accountNo));
     //console.log(response.data.rx89tuv);
    }).catch((error)=>console.log(error));
  };
  const getEmail = (event) => {
    setEmailValue(event.target.value);
  };

  const getPassword = (event) => {
    setPasswordValue(event.target.value);
  };

  const handleOnChange = ()=>{
    setStayLoggedInValue(!stayLoggedInValue);
  }

  useEffect(() => {
    if (emailValue == "") {
      setMessage(false);
    }

    else {
      setMessage(true);
    }
  },[]);


  let loginBool = true;


  if (login.displayMessage == 0 && login.loginResult == false) {
    alertMessage = loginMessage[1];
    alertClassText = alertClass[1];
    loginBool = false;
  }
  if (login.displayMessage == 1 && login.loginResult == true) {
    alert("You are now logged in.");
    setLogin({
      loginResult: true,
      displayMessage: -1
    });
    loginBool = true;
    setAuth(setAuthentication(loginBool));
    
    //setCookie('RCF4589', "", { path: '/', expires:0,maxAge:1000,domain:'http://localhost',secure:false,httpOnly:true });
    props.handleClose();
  }


  return (
    <div className={showHideClassName}>
      <div className="modal-main2">
        <div className="close-button"><i class="fas fa-times-circle close-icon modal-close" onClick={() => props.handleClose()}></i></div>
        <div className="modal-header-div"><h2 className="modal-header">LOGIN</h2></div>
        {message ? <div class="alert alert-danger" role="alert"> <p>Email text box must have a value.</p></div> : ""}
        {!loginBool ? <div class={"alert " + alertClassText} role="alert"> <p>{alertMessage}</p></div> : ""}
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <div className="form-group login-text-field">
              <label className="label-text">Email:
                <input type="email" className="form-control label-text" id="loginEmail" name="Email" placeholder="example@email.com" onChange={getEmail} />
              </label>
            </div>
            <div className="form-group login-text-field">
              <label className="label-text">Password:
                <input  type="password" className="form-control label-text" id="loginPassword" name="Password" placeholder="Enter Password" onChange={getPassword} />
              </label>
            </div>
            <div>
              <span className="stayLoggedIn-text" >Stay Logged In</span>
              <input type="checkbox" id="stayLoggedIn-checkbox" onChange={()=>handleOnChange()} name="StayLoggedIn" value="loggedIn"/>
            </div>
            <br />
            <div className="modal-text-div"><Link to={"/registration"}><span className="span-font registerSpan login-modal-links">WANT TO REGISTER?</span></Link><br className="break-modal"/><br className="break-modal"/><Link to={"/reset_password"}><span className="span-font passwordSpan">FORGOT YOUR PASSWORD?</span></Link></div>
            <br className="break-modal"/>
            <div id="button-div"><button className="btn btn-primary" type="submit" value="Submit" style={{ backgroundColor: "#c85d4d" }}>LOG IN</button></div>
          </div>
        </form>
      </div>
    </div>
  );
}

/*const mapStateToProps = (state) => {
  return {
      cartItems: state.cartItems
  }}


const mapDispatchToProps = (dispatch)=>{
return{
setAuth: () => (dispatch(setAuthentication(true))),
setToken: (token) => (dispatch(setToken(token)))
}
}*/

export default Modal;
