import { Outlet } from "react-router-dom";
import Footer from './Footer';
import NavBar from './navBar';
import '../css/mainBackgroundSection.css';


function App() {

    /*const [products, setProducts] = useState([]);

   const  updateCartx = (cart)=>
    {
        setProducts(cart);
    }*/

    //console.log(products);

      
    return (
        
       <div> 

        <Outlet/>
         <Footer/>
        </div>
        
    );
}

export default App;