import React from 'react';
import {Link} from 'react-router-dom';
import NavBar from './navBar';
import '../css/freeSounds.css';

function FreeSounds(props) {



    return(
        
             <div className="mic-background-image-free">
            <NavBar/>
            <div className="format-div-free"></div>
            <h1 className="catalog-title-free">Free Sounds</h1>
            <div className="product-container-free">
            <div className="account-div">
            <Link to="/free_beats" className="nav-bar-links" style={{textDecoration:"none"}}><div className="account-text">FREE BEATS</div></Link>
            <Link to="/free_loop_sets" classname="nav-bar-links" style={{textDecoration:"none"}}><div className="account-text">FREE LOOP SETS</div></Link>
            <Link to="/free_sound_patches" className="nav-bar-links" style={{textDecoration:"none"}}><div className="account-text">FREE SOUND PATCHES</div></Link>
            </div>
        </div>
            </div>
        
        
    );
}

export default FreeSounds;