import { RouterProvider} from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import RouterPaths from './assets/js/RouterPaths';
import Footer from './assets/js/Footer';
import { Provider } from 'react-redux';
//import store from './assets/js/store';
import {persistor, store} from './assets/js/store';
import {PersistGate} from 'redux-persist/integration/react';






   const router = RouterPaths(); 
    //console.log(router);



    ReactDOM.createRoot(document.getElementById("root")).render(
      <Provider store={store}><PersistGate loading={null} persistor={persistor}><RouterProvider router={router}/></PersistGate></Provider>
      );