import React from 'react';
import "../css/menuItems.css";

const MenuItems = (props) =>{

    return(
        <div className="menu-format">
                <div className="dropdown-item-format"  onClick={()=>props.handleDropDown(0)}>Display All</div>
                <div className="dropdown-item-format"  onClick={()=>props.handleDropDown(1)}>Hip Hop</div>
                <div className="dropdown-item-format"  onClick={()=>props.handleDropDown(2)}>RnB</div>
                <div className="dropdown-item-format"  onClick={()=>props.handleDropDown(3)}>Pop</div>
                <div className="dropdown-item-format"  onClick={()=>props.handleDropDown(4)}>Dancehall</div>
            </div>
    );
}

export default MenuItems;