import axios from 'axios';

    export const loader = async () =>{

        const url_production = 'api/CatalogController.php';
        const url_development = 'api/CatalogController.php';

        const sortInfo = {
            order:0,
            offset:0
        }

        const products = await axios.post(url_production,sortInfo);
   
    return {products};
    }
