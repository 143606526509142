// @flow
import React, { useState, useEffect} from 'react';
import {NavLink,Link /*withRouter*/} from 'react-router-dom';
import '../css/mainBackgroundSection.css';
import $ from 'jquery';
import MainBackgroundSection from './mainBackgroundSection';
import { useSelector, useDispatch} from 'react-redux';
import CartIcon2 from './CartIcon';
import CartIcon1 from './CartIcon1';
//import {useToken} from './Authorization';
import {addToCart, removeFromCart, setEmptyCart, setAuthentication, setToken, setCustomer, setModalState} from './cartSlice';
import axios from 'axios';
import '../css/navBar.css';
import localStorage from 'redux-persist/es/storage';
import Cookie_Modal from './Cookie_Modal';

//const num = 10;
//const count = 0;

function NavBar(props){


const cartItem = useSelector((state) => state.cart.cartItems);
const isAuth = useSelector((state) => state.cart.isAuth);
const token = useSelector((state)=>state.cart.token)
const isShowing = useSelector((state)=>state.cart.isShowing);


const setTokenValue = useDispatch();
const setAuth = useDispatch();
const setModal = useDispatch();
const emptyCart = useDispatch();
const addCartItem = useDispatch();


//const cartItemsArray = [];
const hideModal = () =>{
  setModal(setModalState(false));
}
    
console.log(isShowing);
console.log(isAuth);

  //const [cartItems, setCartItems] = useState([]);
  var num = 0;
  const url_d = "api/LogoutController.php";
{

   
  useEffect(
  function(){
    
    axios.get('api/JwtController.php').then((res)=>{
        if(!res.data.result){
         // setAuth(setAuthentication(false));
        }
    }).catch((err)=>{})
    $('.hamburger-div').click(function()
    {
        $('.dropdown-content').toggle('slow');
    });
    $(window).scroll(function(){
      $('.nav-bar').toggleClass('scrolled', $(this).scrollTop() > 100);
    });
  },[]);

   const loggedOut = ()=>{
    //setModal(setModalState(true));
    axios.get(url_d).then((res)=>{
      setAuth(setAuthentication(false));
    emptyCart(setEmptyCart([]));
    alert("You are logged out.");
   }).catch((err)=>{/*console.log(err)*/});
  }
    return (
        <div>
        <nav className="nav-bar bg">
       <Link to="/"  className="image-link"><div className="navbar-image-div"><img className="main-logo-image" src={"Logos/ShowLove_Logo2_1.png"} alt="Show Love Productions"/></div></Link>
        <div className="nav-div">
            <NavLink to="/about_us" className="nav-bar-links nav-text" acitveClassName="active" style={{textDecoration:"none"}}>ABOUT US</NavLink>
           <NavLink to="/contact_us" className="nav-bar-links nav-text" acitveClassName="active"  style={{textDecoration:"none"}}>CONTACT US</NavLink>
            <NavLink to="/free_sounds" className="nav-bar-links nav-text" acitveClassName="active"  style={{textDecoration:"none"}}>FREE SOUNDS</NavLink>
           <NavLink to="/customer_account" className="nav-bar-links nav-text" acitveClassName="active"  style={{textDecoration:"none"}}>USER ACCOUNT</NavLink>
           <NavLink to="/catalog" className="nav-bar-links nav-text" acitveClassName="active"  style={{textDecoration:"none"}}>LOOPS &amp; BEATS</NavLink>
        </div>
      
       { isAuth ? <CartIcon2 cartNumber={cartItem.length} loggOut={loggedOut}/> : <CartIcon1 cartNumber={cartItem.length}/>}
        
        <div id="1" className="hamburger-div">
            <i className="fas fa-bars formatt-bars burger"></i>
        </div>
    </nav>
    <MainBackgroundSection/>
    {isShowing ? <Cookie_Modal show = {isShowing} hideModal={hideModal}/> : ""}
    </div>
    )}
  }
export default NavBar;
